class VideoParser {
    constructor () {
        if (!VideoParser.instance) {
            VideoParser.instance = this;
        }

        return VideoParser.instance;
    }

    embedCodeFromUrl (url, autoplay = 0) {
        if (!instance.validLink(url)) {
            return null;
        }

        const service = instance.identifyService(url);

        if (service) {
            const videoId = instance.getVideoId(url, service);
            let embedCode = '';

            if (service == 'youtube') {
                embedCode = `//youtube.com/embed/${videoId}?autoplay=${autoplay}`;
            } else if (service == 'vimeo') {
                embedCode = `//player.vimeo.com/video/${videoId}?byline=0&amp;portrait=0&amp;autoplay=${autoplay}`;
            } else if (service == 'wistia') {
                embedCode = `//fast.wistia.net/embed/iframe/${videoId}`;
            } else if (service == 'screencast-o-matic') {
                embedCode = `//screencast-o-matic.com/embed?sc=${videoId}&v=5&ff=1`;
            } else if (service == 'loom') {
                embedCode = `https://www.loom.com/embed/${videoId}`;
            }

            if (embedCode) {
                return `<div class="videoWrapper form-group"><iframe src="${embedCode}" width="100%"  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" frameborder="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen="true" id="video_player" ></iframe></div>`;
            }
        } else {
            return `<div class="videoWrapper form-group"><iframe src="${url}" width="100%"  allow="accelerometer; autoplay=${autoplay}; encrypted-media; gyroscope; picture-in-picture" frameborder="0" scrolling="no" webkitallowfullscreen mozallowfullscreen allowfullscreen="true" id="video_player" ></iframe></div>`;
        }

        return null;
    }

    identifyService (url) {
        if (new RegExp('youtube|youtu\.be').test(url)) {
            return 'youtube';
        } else if (new RegExp('vimeo').test(url)) {
            return 'vimeo';
        }  else if (new RegExp('wistia').test(url)) {
            return 'wistia';
        } else if (new RegExp('screencast-o-matic').test(url)) {
            return 'screencast-o-matic';
        } else if (new RegExp('loom').test(url)) {
            return 'loom';
        }

        return null;
    }

    getVideoId(url, service) {
        let string = null;

        try {
            string = new URL(url);
        } catch (e) {
            return null;
        }

        if (string) {
            if (service == 'youtube') {
                let code = new URLSearchParams(string.search).get('v') ? new URLSearchParams(string.search).get('v') : new URLSearchParams(string.search).get('vi');

                return code ? code : string.pathname.substring(string.pathname.lastIndexOf('/') + 1);
            } else if (service == 'vimeo') {
                return string.pathname.substring(string.pathname.lastIndexOf('/') + 1);
            } else if (service == 'wistia') {
                return string.pathname.substring(string.pathname.lastIndexOf('/') + 1);
            } else if (service == 'screencast-o-matic') {
                return string.pathname.substring(string.pathname.lastIndexOf('/') + 1);
            } else if (service == 'loom') {
                return string.pathname.substring(string.pathname.lastIndexOf('/') + 1);
            }
        }

        return null;
    }

    validLink (str) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_().~+]*)*'+ // port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
          '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

        return !!pattern.test(str);
    }
}

const instance = new VideoParser();
Object.freeze(instance);

export default instance;