<template>
    <div class="preview_area show_sidebar" v-if="modelValue">
        <div class="global_setting" :style="`--var-chat-bg-color: ${form.chat_button_bg_color != null ? form.chat_button_bg_color : '#2f7eed'}; --var-chat-bg-color90: ${form.chat_button_bg_color != null ? form.chat_button_bg_color + 90 : '#2f7eed90'}; --var-welcome-bg-color: ${form.chat_button_bg_color}; --var-welcome-text-color: ${welcome_text_color}; --var-coach-bubble-bg-color: ${form.chat_coach_bubble_bg_color}; --var-coach-bubble-text-color: ${form.chat_coach_bubble_text_color}; --var-client-bubble-bg-color: ${form.chat_client_bubble_bg_color}; --var-client-bubble-text-color: ${form.chat_client_bubble_text_color};`">
            <button class="close_btn modal-window-close-button" @click="closeModal()"><i class="fas fa-long-arrow-alt-left"></i></button>
            <!-- <ul class="tabs_btn">
                <li :class="tab === 'portal' ? 'active' : ''" @click="switchTab('portal')">Main</li>
                <li :class="tab === 'login' ? 'active' : ''" @click="switchTab('login')">Login Screen</li>
                <li :class="tab === 'seo' ? 'active' : ''" @click="switchTab('seo')">SEO</li>
                <li :class="tab === 'chat' ? 'active' : ''" @click="switchTab('chat')">Chat</li>
                <li :class="tab === 'footer' ? 'active' : ''" @click="switchTab('footer')">Footer</li>
                <li :class="tab === 'delivery' ? 'active' : ''" @click="switchTab('delivery')">Delivery Message</li>
            </ul> -->
            <div class="tabs_content">
                <!-- <button type="button" class="toggle_bar" @click="toggleSectionbar"><img src="@/assets/images/bar-left.svg" alt=""></button> -->
                <Form @submit="handleSubmit" v-slot="{ errors }" class="client-portal-setting-form" ref="client-portal-setting-form">
                    <div class="content_area">
                        <div class="info_bar">
                            <!-- <div class="time">Estimated Time: <span>6 min.</span></div> -->
                            <div class="step_bar2">
                                <ul>
                                    <li :class="{ active : step == 0 || step > 0 }">
                                        <span @click="step = 0">1</span>
                                        <h6>Login</h6>
                                        <!-- <h6>Login <label>Screen</label></h6> -->
                                    </li>
                                    <li :class="{ active : step == 1 || step > 1 }">
                                        <span @click="step = 1">2</span>
                                        <h6>Branding</h6>
                                        <!-- <h6>Portal <label>Branding</label></h6> -->
                                    </li>
                                    <!-- <li :class="{ active : step == 2 || step > 2 }">
                                        <span @click="step = 2">3</span>
                                        <h6>Onboarding</h6>
                                    </li> -->
                                    <li :class="{ active : step == 2 || step > 2 }">
                                        <span @click="step = 2">3</span>
                                        <h6>Chat</h6>
                                    </li>
                                    <li :class="{ active : step == 3 || step > 3 }">
                                        <span @click="step = 3">4</span>
                                        <h6>Notifications</h6>
                                    </li>
                                    <li :class="{ active : step == 4 || step > 4 }">
                                        <span @click="step = 4">5</span>
                                        <h6>SEO</h6>
                                    </li>
                                </ul>
                                <div class="total_bar"><span :style="`width:${(100 / 4) * step }%`"></span></div>
                            </div>
                            <button type="button" class="tutorial_btn" @click="handleTutorialVideo()">Tutorial</button>
                        </div>
                        <ul class="btn_list" v-if="step == 0 || step == 1 || step == 4">
                            <li v-if="toggleAll" @click="openAllSection()">Open All</li>
                            <li v-else @click="closeAllSection()">Close All</li>
                        </ul>
                        <div class="content_wpr" v-if="step === 0">
                            <div class="section_content w-100 open_area">
                                <div class="section_header">
                                    <h2>Portal URL</h2>
                                </div>
                                <div class="setting_wpr">
                                    <div class="form_grp p-0">
                                        <div class="group_item">
                                            <div class="field_wpr has_suffix">
                                                <Field autocomplete="off" type="text" name="portal_login_url" v-model="form.portal_url" placeholder="https://superfitcoaching.com" />
                                                <span class="suffix pointer" @click="handleCopy(form.portal_url)">Copy</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Login Theme</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="layout_content">
                                        <ul>
                                            <li>
                                                <div class="section_header m-0 pb-2">
                                                    <h3 class="sub_heading2 m-0">Layout 1</h3>
                                                    <label for="layout1" class="switch_option capsule_btn p-0">
                                                        <input type="radio" id="layout1" :value="1" v-model="form.login_layout" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <img src="@/assets/images/wireframe-1.svg" :class="form.login_layout === 1 ? 'active' : ''">
                                            </li>
                                            <li>
                                                <div class="section_header m-0 pb-2">
                                                    <h3 class="sub_heading2 m-0">Layout 2</h3>
                                                    <label for="layout2" class="switch_option capsule_btn p-0">
                                                        <input type="radio" id="layout2" :value="2" v-model="form.login_layout" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <img src="@/assets/images/wireframe-2.svg" :class="form.login_layout === 2 ? 'active' : ''">
                                            </li>
                                            <li>
                                                <div class="section_header m-0 pb-2">
                                                    <h3 class="sub_heading2 m-0">Layout 3</h3>
                                                    <label for="layout3" class="switch_option capsule_btn p-0">
                                                        <input type="radio" id="layout3" :value="3" v-model="form.login_layout" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                                <img src="@/assets/images/wireframe-3.svg" :class="form.login_layout === 3 ? 'active' : ''">
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Login Cover Image</h2>
                                    <span class="status" :style="`color: ${form.login_setting.has_cover ? '#2f7eed' : '#999'};`">{{ form.login_setting.has_cover ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <label for="has-login-cover" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="has-login-cover" :true-value="1" :false-value="0" v-model="form.login_setting.has_cover" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="setting_wpr" v-if="form.login_setting.has_cover">
                                                <div class="cover_type login_cover">
                                                    <h3 class="sub_header">Cover Type</h3>
                                                    <ul class="type_list">
                                                        <li>
                                                            <label for="clr_text">
                                                                <input type="radio" id="clr_text" :value="1" v-model="form.login_setting.cover_type" hidden>
                                                                <img src="@/assets/images/background.svg" alt="">
                                                                <h5>Color + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_text">
                                                                <input type="radio" id="img_text" :value="2" v-model="form.login_setting.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image + Text</h5>
                                                            </label>
                                                        </li>
                                                        <li>
                                                            <label for="img_only">
                                                                <input type="radio" id="img_only" :value="3" v-model="form.login_setting.cover_type" hidden>
                                                                <img src="@/assets/images/picture.svg" alt="">
                                                                <h5>Image Only</h5>
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div v-if="form.login_setting.cover_type !== 3">
                                                    <div class="setting_wpr mt-3">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">
                                                                    Headline 
                                                                    <label for="is_login_headline" class="switch_option capsule_btn ml-auto p-0">
                                                                        <input type="checkbox" id="is_login_headline" :true-value="1" :false-value="0" v-model="form.login_setting.is_headline" hidden>
                                                                        <div><span></span></div>
                                                                    </label>&nbsp;
                                                                    <button type="button" class="px-1" @click="loginHeadlineSetting = !loginHeadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </label>
                                                                <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                    <Field autocomplete="nofill" type="text" name="headline" v-model="form.login_setting.headline" placeholder="Compelling Headline Goes Here" />
                                                                </div>
                                                                <ErrorMessage name="headline" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div v-if="loginHeadlineSetting">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Family</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.login_setting.headline_setting_font_family" v-bind="fontFamily"></multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Weight</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.login_setting.headline_setting_font_weight" v-bind="fontWeight"></multiselect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Size</label>
                                                                    <div class="field_wpr m-0">
                                                                        <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.login_setting.headline_setting_font_size" min="0" max="100" placeholder="90" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Color <a class="reset-default" @click="form.login_setting.headline_setting_font_color = defaultForm.login_setting.headline_setting_font_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="headline_setting_font_color" v-model="form.login_setting.headline_setting_font_color" type="text" label="element color">
                                                                        <color-picker v-model="form.login_setting.headline_setting_font_color" :classes="{ 'has-error': errors.headline_setting_font_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="headline_setting_font_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="cover_type mt-4 mb-3 pb-4 login_cover">
                                                                <label for="headline_shadow" class="switch_option capsule_btn">
                                                                    <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                    <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.login_setting.headline_setting_has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                                <div v-if="form.login_setting.headline_setting_has_shadow">
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-x</label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.login_setting.headline_setting_shadow_x" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-y</label>
                                                                            <div class="field_wpr ">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.login_setting.headline_setting_shadow_y" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp mb-3">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Blur-Radius</label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.login_setting.headline_setting_shadow_blur" min="0" max="100" placeholder="68" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp mb-2">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Shadow Color <a class="reset-default" @click="form.login_setting.headline_setting_shadow_color = defaultForm.login_setting.headline_setting_shadow_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.login_setting.headline_setting_shadow_color" type="text" label="element color">
                                                                                <color-picker v-model="form.login_setting.headline_setting_shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                            </Field>
                                                                            <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">
                                                                    Sub Headline
                                                                    <label for="is_login_subheadline" class="switch_option capsule_btn ml-auto p-0">
                                                                        <input type="checkbox" id="is_login_subheadline" :true-value="1" :false-value="0" v-model="form.login_setting.is_subheadline" hidden>
                                                                        <div><span></span></div>
                                                                    </label>&nbsp;
                                                                    <button type="button" class="px-1" @click="loginSubheadlineSetting = !loginSubheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                                </label>
                                                                <div class="field_wpr">
                                                                    <Field autocomplete="nofill" type="text" name="sub_headline" v-model="form.login_setting.subheadline" placeholder="Enter text here" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="loginSubheadlineSetting">
                                                            <div class="form_grp">
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Family</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.login_setting.subheadline_setting_font_family" v-bind="fontFamily"></multiselect>
                                                                    </div>
                                                                </div>
                                                                <div class="group_item">
                                                                    <label class="input_label">Font-Weight</label>
                                                                    <div class="field_wpr m-0">
                                                                        <multiselect v-model="form.login_setting.subheadline_setting_font_weight" v-bind="fontWeight"></multiselect>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Size</label>
                                                                    <div class="field_wpr m-0">
                                                                        <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.login_setting.subheadline_setting_font_size" min="0" max="100" placeholder="90" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="form_grp mb-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Color <a class="reset-default" @click="form.login_setting.subheadline_setting_font_color = defaultForm.login_setting.subheadline_setting_font_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.login_setting.subheadline_setting_font_color" type="text" label="element color">
                                                                        <color-picker v-model="form.login_setting.subheadline_setting_font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                            <div class="cover_type mt-4 mb-3 pb-4 login_cover">
                                                                <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                    <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                    <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.login_setting.subheadline_setting_has_shadow" hidden>
                                                                    <div><span></span></div>
                                                                </label>
                                                                <div v-if="form.login_setting.subheadline_setting_has_shadow">
                                                                    <div class="form_grp">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-x</label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.login_setting.subheadline_setting_shadow_x" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="group_item">
                                                                            <label class="input_label">Offset-y</label>
                                                                            <div class="field_wpr ">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.login_setting.subheadline_setting_shadow_y" min="0" max="100" placeholder="4" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp mb-3">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Blur-Radius</label>
                                                                            <div class="field_wpr">
                                                                                <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.login_setting.subheadline_setting_shadow_blur" min="0" max="100" placeholder="68" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form_grp mb-2">
                                                                        <div class="group_item">
                                                                            <label class="input_label">Shadow Color <a class="reset-default" @click="form.login_setting.subheadline_setting_shadow_color = defaultForm.login_setting.subheadline_setting_shadow_color">Reset to default</a></label>
                                                                            <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.login_setting.subheadline_setting_shadow_color" type="text" label="element color">
                                                                                <color-picker v-model="form.login_setting.subheadline_setting_shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                            </Field>
                                                                            <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="form.login_setting.cover_type !== 1">
                                                    <h3 class="sub_header">Cover Image</h3>
                                                    <image-library v-model="form.login_setting.cover" image-type="public-cover" upload-text="Cover" />
                                                    <div class="cover_type mt-5 mb-2 py-2">
                                                        <label for="small_login_image" class="switch_option capsule_btn">
                                                            <h5 class="large">Small Screen Image</h5>
                                                            <input type="checkbox" id="small_login_image" :true-value="1" :false-value="0" v-model="form.login_setting.display_small_cover" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                        <div v-if="form.login_setting && form.login_setting.display_small_cover" class="upload_image">
                                                            <image-library v-model="form.login_setting.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                                        </div>
                                                    </div>
                                                    <label for="overlay" class="switch_option capsule_btn">
                                                        <h5 class="large">Add Image Overlay</h5>
                                                        <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.login_setting.has_cover_overlay" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div v-if="form.login_setting.has_cover_overlay">
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Color <a class="reset-default" @click="form.login_setting.overlay_color = defaultForm.login_setting.overlay_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="overlay_color" v-model="form.login_setting.overlay_color" type="text" label="element color">
                                                                    <color-picker v-model="form.login_setting.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                                </Field>
                                                                <ErrorMessage name="overlay_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Overlay Opacity</label>
                                                                <div class="field_wpr">
                                                                    <Field autocomplete="nofill" name="overlay_opacity" v-model="form.login_setting.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                                                </div>
                                                                <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="form.login_setting.cover_type === 1" class="mt-2">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="form.cover_background_color = defaultForm.cover_background_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="cover_background_color" v-model="form.login_setting.cover_background_color" type="text" label="element color">
                                                                <color-picker v-model="form.login_setting.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                            </Field>
                                                            <ErrorMessage name="cover_background_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Login Area</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container pt-2">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Login Headline</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.login_title }">
                                                        <Field autocomplete="off" type="text" name="login_title" v-model="form.login_title" placeholder="ex: Secure Client Portal" rules="required" label="login title" />
                                                    </div>
                                                    <ErrorMessage name="login_title" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <label for="login_thumbnail" class="switch_option capsule_btn p-0 border-0 mt-2">
                                            <h5 class="large">Login Thumbnail</h5>
                                            <input type="checkbox" id="login_thumbnail" :true-value="1" :false-value="0" v-model="form.display_login_card" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <div class="edit_section mt-2" v-if="form.display_login_card">
                                            <label for="thumb_image" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Image</h5>
                                                <input type="radio" id="thumb_image" :value="1" v-model="form.thumbnail_type" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="upload_image mb-3 mt-3" v-if="form.thumbnail_type === 1">
                                                <image-library v-model="form.login_card" image-type="dashboard-thumb" upload-text="Image" />
                                            </div>
                                        </div>
                                        <div class="edit_section mt-2" v-if="form.display_login_card">
                                            <label for="login_video" class="switch_option capsule_btn p-0 mt-4 mb-3">
                                                <h5 class="large">Login Video</h5>
                                                <input type="checkbox" id="login_video" :true-value="1" :false-value="0" v-model="form.has_login_video" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div v-if="form.has_login_video" class="border-top py-4">
                                                <label for="thumb_url" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Video Link</h5>
                                                    <input type="radio" id="thumb_url" :value="3" v-model="form.thumbnail_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.thumbnail_type == 3">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.thumbnail_video_link }">
                                                                <Field autocomplete="off" type="text" name="thumbnail_video_link" v-model="form.thumbnail.url" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="required|url" label="video link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="thumbnail_video_link" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="banner_video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                                    <h5 class="large">Embeded Video</h5>
                                                    <input type="radio" id="banner_video" :value="2" v-model="form.thumbnail_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.thumbnail_type == 2">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.thumbnail_embed }">
                                                                <Field autocomplete="off" type="textarea" name="thumbnail_embed" v-model="form.thumbnail.embed" rules="required" label="embed code">
                                                                    <textarea name="thumbnail_embed" v-model="form.thumbnail.embed" placeholder="embeded code here.."></textarea>
                                                                </Field>
                                                            </div>
                                                            <ErrorMessage name="thumbnail_embed" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mt-4">
                                        <h3 class="sub_heading2">Login Button Setting</h3>
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.btn_text }">
                                                        <Field autocomplete="off" type="text" name="btn_text" v-model="form.btn_text" placeholder="ex: Sign In" rules="required" label="button text" />
                                                    </div>
                                                    <ErrorMessage name="btn_text" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Color <a class="reset-default" @click="form.btn_color = defaultForm.btn_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="btn_color" v-model="form.btn_color" type="text" label="element color">
                                                        <color-picker v-model="form.btn_color" :classes="{ 'has-error': errors.btn_color }" />
                                                    </Field>
                                                    <ErrorMessage name="btn_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Button Text Color <a class="reset-default" @click="form.btn_txtcolor = defaultForm.btn_txtcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="btn_txtcolor" v-model="form.btn_txtcolor" type="text" label="element color">
                                                        <color-picker v-model="form.btn_txtcolor" :classes="{ 'has-error': errors.btn_txtcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="btn_txtcolor" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" v-if="form.login_layout == 1">
                                <div class="section_header">
                                    <h2 class="sub_header m-0">
                                        Display Footer
                                    </h2>
                                    <label for="has-login-footer" class="switch_option capsule_btn p-0" @click="goTo('ftrsection')" ref="ftrsetting">
                                        <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.login_setting.display_footer" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step === 1">
                            <div class="section_content w-100" @click="goTo('hdrsection')" ref="hdrsetting">
                                <div class="section_header">
                                    <h2>Portal Header Settings</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Background Color <a class="reset-default" @click="form.header_bgcolor = defaultForm.header_bgcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="header_bgcolor" v-model="form.header_bgcolor" type="text" label="element color">
                                                        <color-picker v-model="form.header_bgcolor" :classes="{ 'has-error': errors.header_bgcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="header_bgcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Header Text Color <a class="reset-default" @click="form.header_textcolor = defaultForm.header_textcolor">Reset to default</a></label>
                                                    <Field autocomplete="off" name="header_textcolor" v-model="form.header_textcolor" type="text" label="element color">
                                                        <color-picker v-model="form.header_textcolor" :classes="{ 'has-error': errors.header_textcolor }" />
                                                    </Field>
                                                    <ErrorMessage name="header_textcolor" class="text-danger" />
                                                </div>
                                            </div>
                                            <h3 class="sub_header">Header Logo</h3>
                                            <image-library v-model="form.logo" image-type="public-logo" upload-text="Logo" />
                                            <h3 class="sub_header mt-5">Header Author Image</h3>
                                            <image-library v-model="form.author" image-type="avatar" upload-text="Logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('bnrsection')" ref="bnrsetting">
                                <div class="section_header">
                                    <h2>Portal Cover Image</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <label for="dashboard-cover" class="switch_option capsule_btn border-0">
                                                <h5 class="large">Display Dashboard Cover <span>Upload a small screen cover for a better view</span></h5>
                                                <input type="checkbox" id="dashboard-cover" :true-value="1" :false-value="0" v-model="form.display_cover" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="cover_type" v-if="form.display_cover">
                                                <h3 class="sub_header">Cover Type</h3>
                                                <ul class="type_list">
                                                    <li>
                                                        <label for="clr_text">
                                                            <input type="radio" id="clr_text" :value="1" v-model="form.cover_type" hidden>
                                                            <img src="@/assets/images/background.svg" alt="">
                                                            <h5>Color + Text</h5>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for="img_text">
                                                            <input type="radio" id="img_text" :value="2" v-model="form.cover_type" hidden>
                                                            <img src="@/assets/images/picture.svg" alt="">
                                                            <h5>Image + Text</h5>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <label for="img_only">
                                                            <input type="radio" id="img_only" :value="3" v-model="form.cover_type" hidden>
                                                            <img src="@/assets/images/picture.svg" alt="">
                                                            <h5>Image Only</h5>
                                                        </label>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div v-if="form.display_cover && form.cover_type !== 3">
                                                <div class="setting_wpr mt-3">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">
                                                                Headline
                                                                <label for="is_portal_headline" class="switch_option capsule_btn ml-auto p-0">
                                                                    <input type="checkbox" id="is_portal_headline" :true-value="1" :false-value="0" v-model="form.is_headline" hidden>
                                                                    <div><span></span></div>
                                                                </label>&nbsp;
                                                                <button type="button" class="px-1" @click="headlineSetting = !headlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                            </label>
                                                            <div class="field_wpr" :class="{ 'has-error': errors.headline }">
                                                                <Field autocomplete="nofill" type="text" name="headline" v-model="form.headline" placeholder="Compelling Headline Goes Here" />
                                                            </div>
                                                            <ErrorMessage name="headline" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div v-if="headlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.headline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="headline_setting_font_size" v-model="form.headline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.headline_setting.font_color = defaultForm.headline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="headline_setting_font_color" v-model="form.headline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.headline_setting.font_color" :classes="{ 'has-error': errors.headline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="headline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="cover_type mt-4 mb-3 pb-4">
                                                            <label for="headline_shadow" class="switch_option capsule_btn">
                                                                <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <input type="checkbox" id="headline_shadow" :true-value="1" :false-value="0" v-model="form.headline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div v-if="form.headline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x</label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_x" v-model="form.headline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y</label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_y" v-model="form.headline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius</label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="headline_setting_shadow_blur" v-model="form.headline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.headline_setting.shadow_color = defaultForm.headline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="headline_setting_shadow_color" v-model="form.headline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.headline_setting.shadow_color" :classes="{ 'has-error': errors.headline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="headline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">
                                                                Sub Headline
                                                                <label for="is_portal_description" class="switch_option capsule_btn ml-auto p-0">
                                                                    <input type="checkbox" id="is_portal_description" :true-value="1" :false-value="0" v-model="form.is_subheadline" hidden>
                                                                    <div><span></span></div>
                                                                </label>&nbsp;
                                                                <button type="button" class="px-1" @click="subheadlineSetting = !subheadlineSetting"><i class="fas fa-ellipsis-h"></i></button>
                                                            </label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="nofill" type="text" name="sub_headline" v-model="form.description" placeholder="Enter text here" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-if="subheadlineSetting">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Family</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_family" v-bind="fontFamily"></multiselect>
                                                                </div>
                                                            </div>
                                                            <div class="group_item">
                                                                <label class="input_label">Font-Weight</label>
                                                                <div class="field_wpr m-0">
                                                                    <multiselect v-model="form.subheadline_setting.font_weight" v-bind="fontWeight"></multiselect>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Size</label>
                                                                <div class="field_wpr m-0">
                                                                    <Field autocomplete="nofill" type="number" name="subheadline_setting_font_size" v-model="form.subheadline_setting.font_size" min="0" max="100" placeholder="90" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form_grp mb-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Color <a class="reset-default" @click="form.subheadline_setting.font_color = defaultForm.subheadline_setting.font_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="subheadline_setting_font_color" v-model="form.subheadline_setting.font_color" type="text" label="element color">
                                                                    <color-picker v-model="form.subheadline_setting.font_color" :classes="{ 'has-error': errors.subheadline_setting_font_color }" />
                                                                </Field>
                                                                <ErrorMessage name="subheadline_setting_font_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="cover_type mt-4 mb-3 pb-4">
                                                            <label for="subheadline_shadow" class="switch_option capsule_btn">
                                                                <h5 class="large">Drop Shadow<span>Manage Drop Shadow Effect</span></h5>
                                                                <input type="checkbox" id="subheadline_shadow" :true-value="1" :false-value="0" v-model="form.subheadline_setting.has_shadow" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                            <div v-if="form.subheadline_setting.has_shadow">
                                                                <div class="form_grp">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-x</label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_x" v-model="form.subheadline_setting.shadow_x" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="group_item">
                                                                        <label class="input_label">Offset-y</label>
                                                                        <div class="field_wpr ">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_y" v-model="form.subheadline_setting.shadow_y" min="0" max="100" placeholder="4" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-3">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Blur-Radius</label>
                                                                        <div class="field_wpr">
                                                                            <Field autocomplete="nofill" type="number" name="subheadline_setting_shadow_blur" v-model="form.subheadline_setting.shadow_blur" min="0" max="100" placeholder="68" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form_grp mb-2">
                                                                    <div class="group_item">
                                                                        <label class="input_label">Shadow Color <a class="reset-default" @click="form.subheadline_setting.shadow_color = defaultForm.subheadline_setting.shadow_color">Reset to default</a></label>
                                                                        <Field autocomplete="off" name="subheadline_setting_shadow_color" v-model="form.subheadline_setting.shadow_color" type="text" label="element color">
                                                                            <color-picker v-model="form.subheadline_setting.shadow_color" :classes="{ 'has-error': errors.subheadline_setting_shadow_color }" />
                                                                        </Field>
                                                                        <ErrorMessage name="subheadline_setting_shadow_color" class="text-danger" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="form.display_cover && form.cover_type !== 1">
                                                <h3 class="sub_header">Cover Image</h3>
                                                <image-library v-model="form.cover" image-type="public-cover" upload-text="Cover" />
                                                <div class="cover_type mt-5 mb-2 py-2">
                                                    <label for="small_image" class="switch_option capsule_btn">
                                                        <h5 class="large">Small Screen Image</h5>
                                                        <input type="checkbox" id="small_image" :true-value="1" :false-value="0" v-model="form.has_small_screen_cover" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <image-library v-if="form.has_small_screen_cover" v-model="form.small_cover" image-type="public-cover-small" upload-text="Cover" />
                                                </div>
                                                <label for="overlay" class="switch_option capsule_btn">
                                                    <h5 class="large">Add Image Overlay</h5>
                                                    <input type="checkbox" id="overlay" :true-value="1" :false-value="0" v-model="form.has_cover_overlay" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div v-if="form.has_cover_overlay">
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Overlay Color <a class="reset-default" @click="form.overlay_color = defaultForm.overlay_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="overlay_color" v-model="form.overlay_color" type="text" label="element color">
                                                                <color-picker v-model="form.overlay_color" :classes="{ 'has-error': errors.overlay_color }" />
                                                            </Field>
                                                            <ErrorMessage name="overlay_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp mb-2">
                                                        <div class="group_item">
                                                            <label class="input_label">Overlay Opacity</label>
                                                            <div class="field_wpr">
                                                                <Field autocomplete="nofill" name="overlay_opacity" v-model="form.overlay_opacity" type="number" min="0" max="100" placeholder="90" label="element color" />
                                                            </div>
                                                            <ErrorMessage name="overlay_opacity" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="form.display_cover && form.cover_type === 1" class="mt-2">
                                                <div class="form_grp mb-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Background Color <a class="reset-default" @click="form.cover_background_color = defaultForm.cover_background_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="cover_background_color" v-model="form.cover_background_color" type="text" label="element color">
                                                            <color-picker v-model="form.cover_background_color" :classes="{ 'has-error': errors.cover_background_color }" />
                                                        </Field>
                                                        <ErrorMessage name="cover_background_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')" ref="navsetting">
                                <div class="section_header">
                                    <h2>Portal Navigation Bar</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="navbar" v-if="1 == 2">
                                        <div class="category_navbar">
                                            <h4 @click="edit_classroom = true;" v-if="!edit_classroom">{{ form.classroom_tab_name }}</h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="classroom_tab_name" v-model="form.classroom_tab_name" placeholder="Clasroom tab name" rules="required" label="classroom tab name" />
                                                    <ErrorMessage name="classroom_tab_name" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleTabRename('classroom_tab_name', 'edit_classroom' )" v-if="edit_classroom" :disabled="tabRenameLoader.classroom_tab_name">{{ tabRenameLoader.classroom_tab_name ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div v-tooltip="'Reset To Default'">
                                                <button type="button" @click="handleTabReset('classroom_tab_name', 'Classroom')" v-if="form.classroom_tab_name !== 'Classroom'"><i class="fa fa-undo"></i></button>
                                            </div>
                                            <label for="classroom_tab" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="classroom_tab" v-model="form.classroom_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <ul class="navbar_setting" v-if="form.classroom_tab">
                                            <li>
                                                <h4 @click="edit_playbooks = true;" v-if="!edit_playbooks">{{form.playbook_tab_name}}</h4>
                                                <div class="rename_fld" v-else>
                                                    <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="playbook_tab_name" v-model="form.playbook_tab_name" placeholder="Playbook tab name" rules="required" label="playbook tab name" />
                                                    <ErrorMessage name="playbook_tab_name" class="text-danger" />
                                                    </div>
                                                    <button type="button" class="save_btn" @click="handleTabRename('playbook_tab_name', 'edit_playbooks')" v-if="edit_playbooks" :disabled="tabRenameLoader.playbook_tab_name">{{ tabRenameLoader.playbook_tab_name ? 'Saving' : 'Save' }}</button>
                                                </div>
                                                <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('playbook_tab_name', 'Playbooks')" v-if="form.playbook_tab_name !== 'Playbooks'"><i class="fa fa-undo"></i></button>
                                                </div>
                                                <label for="playbooks" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="playbooks" v-model="form.playbook_tab" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <h4 @click="edit_pages = true;" v-if="!edit_pages">{{ form.page_tab_name }}</h4>
                                                <div class="rename_fld" v-else>
                                                    <div class="tab-edit-container">
                                                        <Field autocomplete="nofill" type="text" name="page_tab_name" v-model="form.page_tab_name" placeholder="Page tab name" rules="required" label="page tab name" />
                                                        <ErrorMessage name="page_tab_name" class="text-danger" />
                                                    </div>
                                                    <button type="button" class="save_btn" @click="handleTabRename('page_tab_name', 'edit_pages')" v-if="edit_pages" :disabled="tabRenameLoader.page_tab_name">{{ tabRenameLoader.page_tab_name ? 'Saving' : 'Save' }}</button>
                                                </div>
                                                <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('page_tab_name', 'Pages')" v-if="form.page_tab_name !== 'Pages'"><i class="fa fa-undo"></i></button>
                                                </div>
                                                <label for="pages" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="pages" v-model="form.page_tab" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <h4 @click="edit_forms = true;" v-if="!edit_forms">{{ form.form_tab_name }}</h4>
                                                <div class="rename_fld" v-else>
                                                    <div class="tab-edit-container">
                                                        <Field autocomplete="nofill" type="text" name="form_tab_name" v-model="form.form_tab_name" placeholder="Form tab name" rules="required" label="form tab name" />
                                                        <ErrorMessage name="form_tab_name" class="text-danger" />
                                                    </div>
                                                    <button type="button" class="save_btn" @click="handleTabRename('form_tab_name', 'edit_forms')" v-if="edit_forms" :disabled="tabRenameLoader.form_tab_name">{{ tabRenameLoader.form_tab_name ? 'Saving' : 'Save' }}</button>
                                                </div>
                                                <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('form_tab_name', 'Forms')" v-if="form.form_tab_name !== 'Forms'"><i class="fa fa-undo"></i></button>
                                                </div>
                                                <label for="forms" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="forms" v-model="form.form_tab" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <h4 @click="edit_workouts = true;" v-if="!edit_workouts">{{ form.workout_tab_name }}</h4>
                                                <div class="rename_fld" v-else>
                                                    <div class="tab-edit-container">
                                                        <Field autocomplete="nofill" type="text" name="workout_tab_name" v-model="form.workout_tab_name" placeholder="Workout tab name" rules="required" label="workout tab name" />
                                                        <ErrorMessage name="workout_tab_name" class="text-danger" />
                                                    </div>
                                                    <button type="button" class="save_btn" @click="handleTabRename('workout_tab_name', 'edit_workouts')" v-if="edit_workouts" :disabled="tabRenameLoader.workout_tab_name">{{ tabRenameLoader.workout_tab_name ? 'Saving' : 'Save' }}</button>
                                                </div>
                                                <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('workout_tab_name', 'Workouts')" v-if="form.workout_tab_name !== 'Workouts'"><i class="fa fa-undo"></i></button>
                                                </div>
                                                <label for="workouts" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="workouts" v-model="form.workout_tab" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                            <li>
                                                <h4 @click="edit_files = true;" v-if="!edit_files">{{ form.file_tab_name }}</h4>
                                                <div class="rename_fld" v-else>
                                                    <div class="tab-edit-container">
                                                        <Field autocomplete="nofill" type="text" name="file_tab_name" v-model="form.file_tab_name" placeholder="File tab name" rules="required" label="file tab name" />
                                                        <ErrorMessage name="file_tab_name" class="text-danger" />
                                                    </div>
                                                    <button type="button" class="save_btn" @click="handleTabRename('file_tab_name', 'edit_files')" v-if="edit_files" :disabled="tabRenameLoader.file_tab_name">{{ tabRenameLoader.file_tab_name ? 'Saving' : 'Save' }}</button>
                                                </div>
                                                <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('file_tab_name', 'Files')" v-if="form.file_tab_name !== 'Files'"><i class="fa fa-undo"></i></button>
                                                </div>
                                                <label for="files" class="switch_option capsule_btn p-0">
                                                    <input type="checkbox" id="files" v-model="form.file_tab" :true-value="1" :false-value="0" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="navbar mt-4">
                                        <div class="category_navbar">
                                            <h4 @click="edit_calendar = true;" v-if="!edit_calendar">{{ form.calendar_tab_name }}</h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="calendar_tab_name" v-model="form.calendar_tab_name" placeholder="Calendar tab name" rules="required" label="calendar tab name" />
                                                    <ErrorMessage name="calendar_tab_name" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleTabRename('calendar_tab_name', 'edit_calendar')" v-if="edit_calendar" :disabled="tabRenameLoader.calendar_tab_name">{{ tabRenameLoader.calendar_tab_name ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                    <button type="button" @click="handleTabReset('calendar_tab_name', 'Calendar')" v-if="form.calendar_tab_name !== 'Calendar'"><i class="fa fa-undo"></i></button>
                                                </div>
                                            <label for="calendar" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="calendar" v-model="form.calendar_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="calendar_setting" v-if="form.calendar_tab">
                                            <div class="setting_wpr">
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Calendar Event Color <a class="reset-default" @click="form.calendar_settings.event_color = defaultForm.calendar_settings.event_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_event_color" v-model="form.calendar_settings.event_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.event_color" :classes="{ 'has-error': errors.calendar_event_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_event_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp py-2">
                                                    <div class="group_item">
                                                        <label class="input_label">Calendar Event Text Color <a class="reset-default" @click="form.calendar_settings.text_color = defaultForm.calendar_settings.text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="calendar_text_color" v-model="form.calendar_settings.text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.calendar_settings.text_color" :classes="{ 'has-error': errors.calendar_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="calendar_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="navbar mt-4">
                                        <div class="category_navbar">
                                            <h4 @click="edit_chart = true;" v-if="!edit_chart">{{ form.progress_tab_name }}</h4>
                                            <div class="rename_fld" v-else>
                                                <div class="tab-edit-container">
                                                    <Field autocomplete="nofill" type="text" name="progress_tab_name" v-model="form.progress_tab_name" placeholder="Progress tab name" rules="required" label="progress tab name" />
                                                    <ErrorMessage name="progress_tab_name" class="text-danger" />
                                                </div>
                                                <button type="button" class="save_btn" @click="handleTabRename('progress_tab_name', 'edit_chart')" v-if="edit_chart" :disabled="tabRenameLoader.progress_tab_name">{{ tabRenameLoader.progress_tab_name ? 'Saving' : 'Save' }}</button>
                                            </div>
                                            <div class="reset_tab" v-tooltip="'Reset To Default'">
                                                <button type="button" @click="handleTabReset('progress_tab_name', 'Tracking')" v-if="form.progress_tab_name !== 'Tracking'"><i class="fa fa-undo"></i></button>
                                            </div>
                                            <label for="progress" class="switch_option capsule_btn p-0">
                                                <input type="checkbox" id="progress" v-model="form.progress_tab" :true-value="1" :false-value="0" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                        <div class="calendar_setting" v-if="form.progress_tab">
                                            <h3 class="sub_heading2">Activity Settngs</h3>
                                            <ul>
                                                <li>
                                                    <div class="setup_controller">
                                                        <h3 class="sub_header mt-1 mb-2">
                                                            Habit Score
                                                            <label for="has-habit-score" class="switch_option capsule_btn p-0">
                                                                <input type="checkbox" id="has-habit-score" :true-value="1" :false-value="0" v-model="form.has_habit_score" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </h3>
                                                        <div class="setting_wpr">
                                                            <div class="form_grp py-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Habit Score Color <a class="reset-default" @click="form.habit_score_color = defaultForm.habit_score_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="habit_score_color" v-model="form.habit_score_color" type="text" label="element color">
                                                                        <color-picker v-model="form.habit_score_color" :classes="{ 'has-error': errors.habit_score_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="habit_score_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="setup_controller">
                                                        <h3 class="sub_header mt-1 mb-2">
                                                            Progress Score
                                                            <label for="has-progress-color" class="switch_option capsule_btn p-0">
                                                                <input type="checkbox" id="has-progress-color" :true-value="1" :false-value="0" v-model="form.has_progress_score" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </h3>
                                                        <div class="setting_wpr">
                                                            <div class="form_grp py-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Progress Score Color <a class="reset-default" @click="form.progress_score_color = defaultForm.progress_score_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="progress_score_color" v-model="form.progress_score_color" type="text" label="element color">
                                                                        <color-picker v-model="form.progress_score_color" :classes="{ 'has-error': errors.progress_score_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="progress_score_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="setup_controller">
                                                        <h3 class="sub_header mt-1 mb-2">
                                                            Portals Logins
                                                            <label for="has-portal-login" class="switch_option capsule_btn p-0">
                                                                <input type="checkbox" id="has-portal-login" :true-value="1" :false-value="0" v-model="form.has_portal_login" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </h3>
                                                        <div class="setting_wpr">
                                                            <div class="form_grp py-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Portals Logins Color <a class="reset-default" @click="form.login_score_color = defaultForm.login_score_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="login_score_color" v-model="form.login_score_color" type="text" label="element color">
                                                                        <color-picker v-model="form.login_score_color" :classes="{ 'has-error': errors.login_score_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="login_score_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div class="setup_controller">
                                                        <h3 class="sub_header mt-1 mb-2">
                                                            Referrals
                                                            <label for="has-referrals" class="switch_option capsule_btn p-0">
                                                                <input type="checkbox" id="has-referrals" :true-value="1" :false-value="0" v-model="form.has_referrals" hidden>
                                                                <div><span></span></div>
                                                            </label>
                                                        </h3>
                                                        <div class="setting_wpr">
                                                            <div class="form_grp py-2">
                                                                <div class="group_item">
                                                                    <label class="input_label">Referrals Color <a class="reset-default" @click="form.referral_color = defaultForm.referral_color">Reset to default</a></label>
                                                                    <Field autocomplete="off" name="referral_score_color" v-model="form.referral_score_color" type="text" label="element color">
                                                                        <color-picker v-model="form.referral_score_color" :classes="{ 'has-error': errors.referral_score_color }" />
                                                                    </Field>
                                                                    <ErrorMessage name="referral_score_color" class="text-danger" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="navbar mt-4">
                                        <!-- <div class="category_navbar">
                                            <h4>Navigation Color Setting</h4>
                                        </div> -->
                                        <div class="calendar_setting border-0">
                                            <h3 class="sub_header m-0 pb-2">Navigation Color Setting</h3>
                                            <div class="setting_wpr">
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Navigation Color <a class="reset-default" @click="form.portal_tab_bg_color = defaultForm.portal_tab_bg_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="portal_tab_bg_color" v-model="form.portal_tab_bg_color" type="text" label="element color">
                                                            <color-picker v-model="form.portal_tab_bg_color" :classes="{ 'has-error': errors.portal_tab_bg_color }" />
                                                        </Field>
                                                        <ErrorMessage name="portal_tab_bg_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Navigation Text Color <a class="reset-default" @click="form.portal_tab_text_color = defaultForm.portal_tab_text_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="portal_tab_text_color" v-model="form.portal_tab_text_color" type="text" label="element text color">
                                                            <color-picker v-model="form.portal_tab_text_color" :classes="{ 'has-error': errors.portal_tab_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="portal_tab_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Chart Card Accent Color <a class="reset-default" @click="form.chart_settings.card_accent_color = defaultForm.chart_settings.card_accent_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="chart_card_accent_color" v-model="form.chart_settings.card_accent_color" type="text" label="element text color">
                                                            <color-picker v-model="form.chart_settings.card_accent_color" :classes="{ 'has-error': errors.chart_card_accent_color }" />
                                                        </Field>
                                                        <ErrorMessage name="chart_card_accent_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <div class="form_grp">
                                                    <div class="group_item">
                                                        <label class="input_label">Graph Accent Color <a class="reset-default" @click="form.chart_settings.graph_accent_color = defaultForm.chart_settings.graph_accent_color">Reset to default</a></label>
                                                        <Field autocomplete="off" name="graph_accent_color" v-model="form.chart_settings.graph_accent_color" type="text" label="element text color">
                                                            <color-picker v-model="form.chart_settings.graph_accent_color" :classes="{ 'has-error': errors.graph_accent_color }" />
                                                        </Field>
                                                        <ErrorMessage name="graph_accent_color" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('navsection')">
                                <div class="section_header">
                                    <h2>Title Text Color</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="group_item">
                                            <label class="input_label">Selected Color <a class="reset-default" @click="form.announcement_title_text_color = defaultForm.announcement_title_text_color">Reset to default</a></label>
                                            <Field autocomplete="off" name="announcement_title_text_color" v-model="form.announcement_title_text_color" type="text" label="element text color">
                                                <color-picker v-model="form.announcement_title_text_color" :classes="{ 'has-error': errors.announcement_title_text_color }" />
                                            </Field>
                                            <ErrorMessage name="announcement_title_text_color" class="text-danger" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Calendar Activity Settings</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="calendar_activity_setting">
                                        <ul>
                                            <li>
                                                <div class="setup_controller">
                                                    <h4 @click="edit_morning = true;" v-if="!edit_morning">{{ form.calendar_settings.morning_text }}</h4>
                                                    <div class="rename_fld" v-else>
                                                        <div class="tab-edit-container">
                                                            <Field autocomplete="nofill" type="text" name="calendar_morning_text" v-model="form.calendar_settings.morning_text" placeholder="Calendar morning text" rules="required" label="calendar morning text" />
                                                            <ErrorMessage name="calendar_morning_text" class="text-danger" />
                                                        </div>
                                                        <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('morning_text', 'edit_morning')" v-if="edit_morning" :disabled="calendarTitleLoader.morning_text">{{ calendarTitleLoader.morning_text ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                    <div class="setting_wpr">
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.morning_text_color = defaultForm.calendar_settings.morning_text_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_morning_text_color" v-model="form.calendar_settings.morning_text_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.morning_text_color" :classes="{ 'has-error': errors.calendar_morning_text_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_morning_text_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Icon Color <a class="reset-default" @click="form.calendar_settings.morning_icon_color = defaultForm.calendar_settings.morning_icon_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_morning_icon_color" v-model="form.calendar_settings.morning_icon_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.morning_icon_color" :classes="{ 'has-error': errors.calendar_morning_icon_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_morning_icon_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sample_preview">
                                                    <h3 class="sub_heading2 mb-4">Preview</h3>
                                                    <div class="drawer_area">
                                                        <div class="header">Activity</div>
                                                        <div class="body_area">
                                                            <div class="slot_label morning">
                                                                <h5>{{form.calendar_settings.morning_text}}</h5>
                                                                <svg class="morning" width="150" height="76" viewBox="0 0 150 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M46.2473 44.5521C60.1386 26.6813 85.3425 27.4029 99.4461 41.2411C105.594 47.2729 109.223 55.3284 110.025 63.7045C110.088 64.3579 109.492 64.8725 108.848 64.7481C78.7707 58.9419 51.5654 62.091 40.322 64.6799C39.6618 64.8319 39.0417 64.3087 39.1041 63.6341C39.7566 56.5804 42.1886 49.7686 46.2473 44.5521Z" fill="#e9e9e9"/>
                                                                    <path d="M0 76C23.3193 69.906 84.5238 61.5505 150 76H0Z" fill="#e9e9e9"/>
                                                                    <path d="M92.8793 25.942C91.2045 27.1984 90.9017 28.6262 92.4836 29.8791C94.0656 31.132 104.297 38.0927 105.203 38.5854C106.519 39.3043 107.494 38.518 107.883 37.2588C108.261 35.9951 113.286 18.1632 113.744 16.527C114.203 14.8907 113.305 12.6221 110.83 13.9384C108.354 15.2547 94.1401 24.996 92.8793 25.942Z" fill="#e9e9e9"/>
                                                                    <path d="M112.462 45.5531C110.451 45.5558 110.155 46.6971 110.699 48.4301C111.242 50.1631 114.834 62.5843 115.231 63.495C115.641 64.3985 116.65 64.9091 117.874 64.1088C119.094 63.3188 135.04 51.1292 136.151 50.4054C137.984 49.2153 137.704 47.2748 135.493 47.1713C133.803 47.1061 113.727 45.5542 112.462 45.5531Z" fill="#e9e9e9"/>
                                                                    <path d="M64.7444 22.2849C64.0584 24.2631 64.6058 25.6162 66.618 25.7702C68.6301 25.9243 81.0047 25.9735 82.0314 25.8754C83.5242 25.7357 83.8933 24.5383 83.5109 23.2773C83.1181 22.0179 77.3124 4.4248 76.7776 2.81188C76.2428 1.19896 74.2301 -0.179877 72.9138 2.29568C71.5975 4.77123 65.2606 20.7956 64.7444 22.2849Z" fill="#e9e9e9"/>
                                                                    <path d="M30.3418 63.4812C32.6732 64.6609 33.8159 64.3358 34.3998 62.6544C35.1693 60.4511 37.6345 50.3001 37.847 47.9731C37.9868 46.4607 37.4349 44.9666 35.0846 45.2204C32.7342 45.4742 15.8492 46.974 13.671 47.6083C11.1366 48.3523 11.1793 51.0958 12.7891 52.048C14.6982 53.1746 28.6938 62.6546 30.3418 63.4812Z" fill="#e9e9e9"/>
                                                                    <path d="M56.368 28.3441C57.6172 27.1589 57.0708 25.662 55.7926 24.9323C54.5143 24.2026 38.6427 14.7148 36.6208 13.4003C34.8367 12.2461 32.8265 12.8907 33.6467 15.8123C34.2352 17.9127 40.6278 36.1874 41.0684 37.4484C41.5688 38.9067 42.7541 39.5487 43.9731 38.5046C45.1718 37.4729 55.0706 29.5868 56.368 28.3441Z" fill="#e9e9e9"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div class="footer"><span>Cancel</span></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="setup_controller">
                                                    <h4 @click="edit_afternoon = true;" v-if="!edit_afternoon">{{ form.calendar_settings.afternoon_text }}</h4>
                                                    <div class="rename_fld" v-else>
                                                        <div class="tab-edit-container">
                                                            <Field autocomplete="nofill" type="text" name="calendar_afternoon_text" v-model="form.calendar_settings.afternoon_text" placeholder="Calendar afternoon text" rules="required" label="calendar afternoon text" />
                                                            <ErrorMessage name="calendar_afternoon_text" class="text-danger" />
                                                        </div>
                                                        <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('afternoon_text', 'edit_afternoon')" v-if="edit_afternoon" :disabled="calendarTitleLoader.afternoon_text">{{ calendarTitleLoader.afternoon_text ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                    <div class="setting_wpr">
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.afternoon_text_color = defaultForm.calendar_settings.afternoon_text_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_afternoon_text_color" v-model="form.calendar_settings.afternoon_text_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.afternoon_text_color" :classes="{ 'has-error': errors.calendar_afternoon_text_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_afternoon_text_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Icon Color <a class="reset-default" @click="form.calendar_settings.afternoon_icon_color = defaultForm.calendar_settings.afternoon_icon_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_afternoon_icon_color" v-model="form.calendar_settings.afternoon_icon_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.afternoon_icon_color" :classes="{ 'has-error': errors.calendar_afternoon_icon_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_afternoon_icon_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sample_preview">
                                                    <h3 class="sub_heading2 mb-4">Preview</h3>
                                                    <div class="drawer_area">
                                                        <div class="header">Activity</div>
                                                        <div class="body_area">
                                                            <div class="slot_label afternoon">
                                                                <h5>{{form.calendar_settings.afternoon_text}}</h5>
                                                                <svg class="afternoon" width="130" height="131" viewBox="0 0 130 131" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M36.8678 42.5524C26.5633 55.7964 26.7438 79.3235 39.9134 90.9863C54.866 104.22 75.9099 104.082 89.1433 91.9095C105.008 77.3072 104.096 53.0054 90.0665 39.2414C75.963 25.4031 50.7591 24.6815 36.8678 42.5524Z" fill="#e9e9e9"/>
                                                                    <path d="M64.0243 19.5452C63.0373 21.3918 63.3663 22.8138 65.3296 23.2807C67.2928 23.7477 79.5074 25.7321 80.5368 25.7958C82.0331 25.8913 82.5849 24.7664 82.4045 23.4611C82.2135 22.1558 79.2315 3.87107 78.9556 2.19435C78.6797 0.517631 76.9074 -1.15909 75.2201 1.08008C73.5328 3.31924 64.7671 18.155 64.0243 19.5452Z" fill="#e9e9e9"/>
                                                                    <path d="M95.6484 32.2374C93.7276 32.8317 93.7807 34.0096 94.8101 35.5059C95.8394 37.0022 102.928 47.816 103.576 48.5695C104.234 49.3123 105.348 49.5034 106.282 48.3785C107.216 47.2642 118.868 30.9215 119.717 29.9027C121.118 28.226 120.279 26.4537 118.136 27.0056C116.501 27.4407 96.8582 31.866 95.6484 32.2374Z" fill="#e9e9e9"/>
                                                                    <path d="M110.293 63.6812C108.914 62.8959 107.12 63.1188 106.653 64.891C106.187 66.6632 104.138 79.5358 103.947 80.6607C103.756 81.7856 104.605 82.8998 105.996 82.6239C107.386 82.348 126.636 79.4933 127.92 79.175C130.159 78.6125 130.902 76.3734 128.578 74.6966C127.071 73.6036 111.599 64.4241 110.293 63.6812Z" fill="#e9e9e9"/>
                                                                    <path d="M94.8952 94.1806C96.381 93.3847 97.6969 93.9896 98.0683 95.6769C98.4397 97.3536 102.547 114.524 103.205 117.697C103.534 119.289 101.708 120.594 100.223 119.66C98.7262 118.727 82.5109 107.425 81.4709 106.501C79.9746 105.196 81.0145 103.838 81.7468 103.148C83.2325 101.736 93.4944 94.934 94.8952 94.1806Z" fill="#e9e9e9"/>
                                                                    <path d="M49.7405 103.604C47.8621 103.424 47.3103 105.101 47.5968 106.586C47.8727 108.083 50.6743 127.004 50.8654 128.415C51.1413 130.464 53.2955 131.313 54.6857 129.254C56.0759 127.195 65.6693 111.85 66.444 110.407C67.8448 107.796 66.8154 107.234 65.4146 106.586C64.0244 105.939 51.7037 103.795 49.7405 103.604Z" fill="#e9e9e9"/>
                                                                    <path d="M22.8809 81.8704C24.1862 79.7268 25.8629 80.2892 27.0833 82.3374C27.9111 83.7382 34.9151 93.1618 35.573 94.1805C36.231 95.2099 36.5069 97.1626 34.4588 97.6295C32.3894 98.107 15.6965 102.49 13.0859 102.957C10.4753 103.424 8.97897 101.376 10.6557 99.1364C11.632 97.8205 22.1381 83.0908 22.8809 81.8704Z" fill="#e9e9e9"/>
                                                                    <path d="M19.3366 65.6339C21.7243 66.6951 22.8492 66.313 23.348 64.6045C24.0059 62.3653 25.9586 52.1034 26.0541 49.7687C26.1177 48.2511 25.4916 46.7867 23.1569 47.1581C20.8223 47.5295 4.03382 49.8748 1.89017 50.6176C-0.603691 51.4878 -0.423285 54.2258 1.23221 55.096C3.19546 56.1254 17.6492 64.891 19.3366 65.6339Z" fill="#e9e9e9"/>
                                                                    <path d="M48.3502 26.4538C49.6979 25.382 49.2841 23.8432 48.0743 23.0048C46.8645 22.1665 31.8801 11.3315 29.9805 9.84575C28.3038 8.54046 26.2451 9.00739 26.8075 11.9894C27.2108 14.1331 31.9862 32.8954 32.3152 34.1901C32.6866 35.6864 33.8115 36.4292 35.1168 35.4954C36.4009 34.5721 46.9494 27.5787 48.3502 26.4538Z" fill="#e9e9e9"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div class="footer"><span>Cancel</span></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="setup_controller">
                                                    <h4 @click="edit_evening = true;" v-if="!edit_evening">{{ form.calendar_settings.evening_text }}</h4>
                                                    <div class="rename_fld" v-else>
                                                        <div class="tab-edit-container">
                                                            <Field autocomplete="nofill" type="text" name="calendar_evening_text" v-model="form.calendar_settings.evening_text" placeholder="Calendar evening text" rules="required" label="calendar evening text" />
                                                            <ErrorMessage name="calendar_evening_text" class="text-danger" />
                                                        </div>
                                                        <button type="button" class="save_btn" @click="handleCalendarTitleUpdate('evening_text', 'edit_evening')" v-if="edit_evening" :disabled="calendarTitleLoader.evening_text">{{ calendarTitleLoader.evening_text ? 'Saving' : 'Save' }}</button>
                                                    </div>
                                                    <div class="setting_wpr">
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Text Color <a class="reset-default" @click="form.calendar_settings.evening_text_color = defaultForm.calendar_settings.evening_text_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_evening_text_color" v-model="form.calendar_settings.evening_text_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.evening_text_color" :classes="{ 'has-error': errors.calendar_evening_text_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_evening_text_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Icon Color <a class="reset-default" @click="form.calendar_settings.evening_icon_color = defaultForm.calendar_settings.evening_icon_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_evening_icon_color" v-model="form.calendar_settings.evening_icon_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.evening_icon_color" :classes="{ 'has-error': errors.calendar_evening_icon_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_evening_icon_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sample_preview">
                                                    <h3 class="sub_heading2 mb-4">Preview</h3>
                                                    <div class="drawer_area">
                                                        <div class="header">Activity</div>
                                                        <div class="body_area">
                                                            <div class="slot_label evening">
                                                                <h5>{{form.calendar_settings.evening_text}}</h5>
                                                                <svg class="evening" width="149" height="139" viewBox="0 0 149 139" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M138.857 11.6433C138.506 10.5822 137.554 9.80412 136.397 9.6485L128.313 8.53086L124.71 1.6128C124.197 0.622484 123.143 0 122 0C120.858 0 119.803 0.622484 119.291 1.6128L115.688 8.53086L107.604 9.6485C106.461 9.80412 105.509 10.5822 105.143 11.6433C104.792 12.7043 105.099 13.8786 105.934 14.6567L111.748 20.0185L110.372 27.5732C110.167 28.6625 110.635 29.7802 111.558 30.431C112.481 31.0959 113.711 31.1808 114.736 30.6715L121.986 27.0639L129.235 30.6715C129.675 30.8837 130.143 30.9969 130.627 30.9969C131.256 30.9969 131.886 30.7988 132.413 30.431C133.336 29.7802 133.805 28.6625 133.6 27.5732L132.223 20.0185L138.037 14.6567C138.901 13.8786 139.209 12.7043 138.857 11.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M33.8574 68.6433C33.5059 67.5822 32.5539 66.8041 31.3969 66.6485L23.3126 65.5309L19.7097 58.6128C19.1971 57.6225 18.1427 57 17.0003 57C15.8579 57 14.8035 57.6225 14.2909 58.6128L10.688 65.5309L2.60367 66.6485C1.46131 66.8041 0.509343 67.5822 0.143202 68.6433C-0.208292 69.7043 0.0992691 70.8786 0.934069 71.6567L6.74837 77.0185L5.37169 84.5732C5.16665 85.6625 5.63531 86.7802 6.55798 87.431C7.48065 88.0959 8.71088 88.1808 9.73608 87.6715L16.9857 84.0639L24.2352 87.6715C24.6746 87.8837 25.1433 87.9969 25.6266 87.9969C26.2563 87.9969 26.8861 87.7988 27.4133 87.431C28.336 86.7802 28.8047 85.6625 28.5996 84.5732L27.2229 77.0185L33.0372 71.6567C33.9013 70.8786 34.2089 69.7043 33.8574 68.6433Z" fill="#e9e9e9"/>
                                                                    <path d="M36.9287 21.0094C36.753 20.4618 36.277 20.0602 35.6985 19.9799L31.6563 19.403L29.8549 15.8324C29.5986 15.3213 29.0713 15 28.5001 15C27.929 15 27.4017 15.3213 27.1454 15.8324L25.344 19.403L21.3018 19.9799C20.7307 20.0602 20.2547 20.4618 20.0716 21.0094C19.8959 21.5571 20.0496 22.1631 20.467 22.5647L23.3742 25.3321L22.6858 29.2313C22.5833 29.7936 22.8177 30.3704 23.279 30.7063C23.7403 31.0495 24.3554 31.0933 24.868 30.8304L28.4928 28.9685L32.1176 30.8304C32.3373 30.94 32.5716 30.9984 32.8133 30.9984C33.1282 30.9984 33.443 30.8962 33.7067 30.7063C34.168 30.3704 34.4023 29.7936 34.2998 29.2313L33.6115 25.3321L36.5186 22.5647C36.9507 22.1631 37.1044 21.5571 36.9287 21.0094Z" fill="#e9e9e9"/>
                                                                    <path d="M143.178 106.716C139.75 107.5 136.206 107.892 132.662 107.892C106.475 107.892 85.167 86.5379 85.167 60.3184C85.167 50.6792 88.0204 41.409 93.428 33.4993C94.5325 31.8851 94.5325 29.7405 93.428 28.1032C92.3235 26.4659 90.3445 25.6819 88.4116 26.12C75.9855 28.9334 64.7101 35.9668 56.6561 45.9518C48.5102 56.0522 44 68.8276 44 81.8567C44 97.1226 49.9369 111.466 60.7061 122.258C71.4753 133.05 85.8113 139 101.045 139C119.891 139 137.494 129.684 148.172 114.072C149.276 112.458 149.276 110.313 148.172 108.676C147.067 107.062 145.088 106.277 143.178 106.716Z" fill="#e9e9e9"/>
                                                                </svg>
                                                            </div>
                                                        </div>
                                                        <div class="footer"><span>Cancel</span></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="setup_controller">
                                                    <div class="setting_wpr">
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Event Tag Color <a class="reset-default" @click="form.calendar_settings.event_tag_color = defaultForm.calendar_settings.event_tag_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_event_tag_color" v-model="form.calendar_settings.event_tag_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.event_tag_color" :classes="{ 'has-error': errors.calendar_event_tag_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_event_tag_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">Event Tag Text Color <a class="reset-default" @click="form.calendar_settings.event_tag_text_color = defaultForm.calendar_settings.event_tag_text_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_event_tag_text_color" v-model="form.calendar_settings.event_tag_text_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.event_tag_text_color" :classes="{ 'has-error': errors.calendar_event_tag_text_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_event_tag_text_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="setting_wpr">
                                                        <div class="form_grp py-2">
                                                            <div class="group_item">
                                                                <label class="input_label">CTA Link Text Color <a class="reset-default" @click="form.calendar_settings.event_cta_color = defaultForm.calendar_settings.event_cta_color">Reset to default</a></label>
                                                                <Field autocomplete="off" name="calendar_event_cta_color" v-model="form.calendar_settings.event_cta_color" type="text" label="element text color">
                                                                    <color-picker v-model="form.calendar_settings.event_cta_color" :classes="{ 'has-error': errors.calendar_event_cta_color }" />
                                                                </Field>
                                                                <ErrorMessage name="calendar_event_cta_color" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="sample_preview">
                                                    <h3 class="sub_heading2 mb-4">Preview</h3>
                                                    <div class="drawer_area">
                                                        <div class="header">Activity</div>
                                                        <div class="body_area">
                                                            <div class="slot_label">
                                                                <h5>Your Time-slot Activities</h5>
                                                            </div>
                                                            <div class="task_title">Task Title<span class="tag">Schedule: 10:10 AM</span></div>
                                                            <div class="task_content">
                                                                <img src="@/assets/images/thumb/task_placeholder.png" alt="Task Details">
                                                                <a>Complete Now <i class="fas fa-long-arrow-alt-right"></i></a>
                                                            </div>
                                                        </div>
                                                        <div class="footer"><span>Cancel</span></div>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div class="setup_controller">
                                                    <div class="toggle_content">
                                                        <label for="calendar_email_notification" class="switch_option capsule_btn border-0"> Calendar Email Notification
                                                            <input type="checkbox" id="calendar_email_notification" :true-value="1" :false-value="0" v-model="form.calendar_email_notification" hidden>
                                                            <div><span></span></div>
                                                        </label>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="section_content w-100" @click="goTo('activity')" ref="activity1">
                                <div class="section_header">
                                    <h2>Habit Score</h2>
                                    <label for="has-habit-score" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-habit-score" :true-value="1" :false-value="0" v-model="form.has_habit_score" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container" v-show="form.has_habit_score">
                                    <div class="group_item">
                                        <label class="input_label">Habit Score Color</label>
                                        <Field autocomplete="off" name="habit_score_color" v-model="form.habit_score_color" type="text" label="element color">
                                            <color-picker v-model="form.habit_score_color" :classes="{ 'has-error': errors.habit_score_color }" />
                                        </Field>
                                        <ErrorMessage name="habit_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="section_content w-100" @click="goTo('activity')" ref="activity2">
                                <div class="section_header">
                                    <h2>Progress Score</h2>
                                    <label for="has-progress-color" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-progress-color" :true-value="1" :false-value="0" v-model="form.has_progress_score" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container" v-show="form.has_progress_score">
                                    <div class="group_item">
                                        <label class="input_label">Progress Score Color</label>
                                        <Field autocomplete="off" name="progress_score_color" v-model="form.progress_score_color" type="text" label="element color">
                                            <color-picker v-model="form.progress_score_color" :classes="{ 'has-error': errors.progress_score_color }" />
                                        </Field>
                                        <ErrorMessage name="progress_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="section_content w-100" @click="goTo('activity')" ref="activity3">
                                <div class="section_header">
                                    <h2>Portals Logins</h2>
                                    <label for="has-portal-login" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-portal-login" :true-value="1" :false-value="0" v-model="form.has_portal_login" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container" v-show="form.has_portal_login">
                                    <div class="group_item">
                                        <label class="input_label">Portals Logins Color</label>
                                        <Field autocomplete="off" name="login_score_color" v-model="form.login_score_color" type="text" label="element color">
                                            <color-picker v-model="form.login_score_color" :classes="{ 'has-error': errors.login_score_color }" />
                                        </Field>
                                        <ErrorMessage name="login_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="section_content w-100" @click="goTo('activity')" ref="activity4">
                                <div class="section_header">
                                    <h2>Referrals</h2>
                                    <label for="has-referrals" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="has-referrals" :true-value="1" :false-value="0" v-model="form.has_referrals" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="color_container" v-show="form.has_referrals">
                                    <div class="group_item">
                                        <label class="input_label">Referrals Color</label>
                                        <Field autocomplete="off" name="referral_score_color" v-model="form.referral_score_color" type="text" label="element color">
                                            <color-picker v-model="form.referral_score_color" :classes="{ 'has-error': errors.referral_score_color }" />
                                        </Field>
                                        <ErrorMessage name="referral_score_color" class="text-danger" />
                                    </div>
                                </div>
                            </div> -->
                            
                            <!-- <div class="section_content w-100" @click="goTo('announcement')" ref="announsetting">
                                <div class="section_header">
                                    <h2>Announcement Settings</h2>
                                    <span class="status" :style="`color: ${form.has_announcement ? '#2f7eed' : '#999'};`">{{ form.has_announcement ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <label for="announcement" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="announcement" :true-value="1" :false-value="0" v-model="form.has_announcement" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div v-if="form.has_announcement">
                                                <div class="setting_wpr">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Background Color <a class="reset-default" @click="form.announcement_bg_color = defaultForm.announcement_bg_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="announcement_bg_color" v-model="form.announcement_bg_color" type="text" label="background color">
                                                                <color-picker v-model="form.announcement_bg_color" :classes="{ 'has-error': errors.announcement_bg_color }" />
                                                            </Field>
                                                            <ErrorMessage name="announcement_bg_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Text Color <a class="reset-default" @click="form.announcement_text_color = defaultForm.announcement_text_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="announcement_text_color" v-model="form.announcement_text_color" type="text" label="background color">
                                                                <color-picker v-model="form.announcement_text_color" :classes="{ 'has-error': errors.announcement_text_color }" />
                                                            </Field>
                                                            <ErrorMessage name="announcement_text_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Announcement Title</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_title }">
                                                                <Field autocomplete="off" name="announcement_title" v-model="form.announcement_title" type="text" placeholder="ex: Click here to sign up!" rules="required" label="link text" />
                                                            </div>
                                                            <ErrorMessage name="announcement_title" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Announcement Title Text Color</label>
                                                            <Field autocomplete="off" name="announcement_title_text_color" v-model="form.announcement_title_text_color" type="text" label="background color">
                                                                <color-picker v-model="form.announcement_title_text_color" :classes="{ 'has-error': errors.announcement_title_text_color }" />
                                                            </Field>
                                                            <ErrorMessage name="announcement_title_text_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Message</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_message }">
                                                                <Field autocomplete="off" name="announcement_message" v-model="form.announcement_message"  :rules="`${form.has_announcement ? 'required' : ''}`" label="message">
                                                                     <redactor v-model="form.announcement_message" name="announcement_message" :default-value="clientPortal.announcement_message" ref="step-editor"  :email-editor="false" :reset-watcher="stepContentResetWatcher" />
                                                                </Field>
                                                            </div>
                                                            <ErrorMessage name="announcement_message" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="announcement_link" class="switch_option capsule_btn p-0 border-0 mt-4 mb-2">
                                                    <h5 class="large">Include Link</h5>
                                                    <input type="checkbox" id="announcement_link" :true-value="1" :false-value="0" v-model="form.has_announcement_link" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.has_announcement_link">
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Link Text</label>
                                                            <div class="field_wpr m-0" :class="{ 'has-error': errors.announcement_link_text }">
                                                                <Field autocomplete="off" name="announcement_link_text" v-model="form.announcement_link_text" type="text" placeholder="ex: Click here to sign up!" rules="required" label="link text" />
                                                            </div>
                                                            <ErrorMessage name="announcement_link_text" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Link Address</label>
                                                            <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.announcement_link }">
                                                                <Field autocomplete="off" name="announcement_link" v-model="form.announcement_link" type="text" placeholder="https://superfitcoaching.com " rules="required|url" label="link" />
                                                                <span class="prefix">URL</span>
                                                            </div>
                                                            <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                            <ErrorMessage name="announcement_link" class="text-danger" />
                                                        </div>
                                                    </div>
                                                    <div class="form_grp">
                                                        <div class="group_item">
                                                            <label class="input_label">Link Color <a class="reset-default" @click="form.announcement_link_color = defaultForm.announcement_link_color">Reset to default</a></label>
                                                            <Field autocomplete="off" name="announcement_link_color" v-model="form.announcement_link_color" type="text" label="background color">
                                                                <color-picker v-model="form.announcement_link_color" :classes="{ 'has-error': errors.announcement_link_color }" />
                                                            </Field>
                                                            <ErrorMessage name="announcement_link_color" class="text-danger" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="section_content w-100" @click="goTo('welcomevideo')" ref="videosetting">
                                <div class="section_header">
                                    <h2>Welcome Video Settings</h2>
                                    <span class="status" :style="`color: ${form.has_welcome_video ? '#2f7eed' : '#999'};`">{{ form.has_welcome_video ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <label for="welcome_video" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="welcome_video" :true-value="1" :false-value="0" v-model="form.has_welcome_video" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <Transition duration="550" name="nested">
                                            <div class="mt-3" v-if="form.has_welcome_video">
                                                <div class="setting_wpr mb-3">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <label class="input_label">Welcome Title</label>
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="welcome_video_title" v-model="form.welcome_video_title" placeholder="ex: Welcome Video" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form_grp m-0">
                                                    <div class="group_item w-100">
                                                        <label class="input_label">Welcome Title Text Color</label>
                                                        <Field autocomplete="off" name="welcome_title_text_color" v-model="form.welcome_title_text_color" type="text" label="background color">
                                                            <color-picker v-model="form.welcome_title_text_color" :classes="{ 'has-error': errors.welcome_title_text_color }" />
                                                        </Field>
                                                        <ErrorMessage name="welcome_title_text_color" class="text-danger" />
                                                    </div>
                                                </div>
                                                <label for="video_url" class="switch_option capsule_btn p-0 border-0">
                                                    <h5 class="large">Video Link</h5>
                                                    <input type="radio" id="video_url" :value="1" v-model="form.welcome_video_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.welcome_video_type == 1">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="text" name="welcome_video_link" v-model="form.welcome_video_link" placeholder="ex:https://www.youtube.com/watch?v=eaRQF-7hhmo" rules="url" label="video link" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <label for="embed_video" class="switch_option capsule_btn p-0 mt-4 mb-3 border-0">
                                                    <h5 class="large">Embeded Video</h5>
                                                    <input type="radio" id="embed_video" :value="2" v-model="form.welcome_video_type" hidden>
                                                    <div><span></span></div>
                                                </label>
                                                <div class="setting_wpr" v-if="form.welcome_video_type == 2">
                                                    <div class="form_grp p-0">
                                                        <div class="group_item">
                                                            <div class="field_wpr m-0">
                                                                <Field autocomplete="off" type="textarea" name="welcome_video_embed_code" v-model="form.welcome_video_embed_code" label="embed code">
                                                                    <textarea name="welcome_video_embed_code" v-model="form.welcome_video_embed_code" placeholder="embeded code here.."></textarea>
                                                                </Field>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Transition>
                                    </div>
                                </div>
                            </div> -->

                            <!-- <div class="section_content w-100" @click="goTo('pintraining')" ref="pinsetting">
                                <div class="section_header">
                                    <h2>Pinned Training</h2>
                                    <span class="status" :style="`color: ${form.pinned_widget ? '#2f7eed' : '#999'};`">{{ form.pinned_widget ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <label for="pinned-training" class="switch_option capsule_btn p-0">
                                            <h5>Pinned Training</h5>
                                            <input type="checkbox" id="pinned-training" :true-value="1" :false-value="0" v-model="form.pinned_widget" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                </div>
                            </div> -->
                            
                            <div class="section_content w-100" @click="goTo('ftrsection')" ref="ftrsetting">
                                <div class="section_header">
                                    <h2>Footer</h2>
                                    <span class="status" :style="`color: ${form.display_footer ? '#2f7eed' : '#999'};`">{{ form.display_footer ? 'On' : 'Off'}}</span>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="white_box">
                                        <label for="has-login-footer" class="switch_option capsule_btn">
                                            <h5>Show</h5>
                                            <input type="checkbox" id="has-login-footer" :true-value="1" :false-value="0" v-model="form.display_footer" hidden>
                                            <div><span></span></div>
                                        </label>
                                        <TransitionGroup duration="550" name="nested" tag="div">
                                            <div class="inner_box" v-show="form.display_footer" key="0">
                                                <h3 class="sub_header m-0">Social Icons</h3>
                                                <div class="color_container mt-2">
                                                    <label for="facebook" class="switch_option capsule_btn p-0 border-0">
                                                        <h5 class="large">Facebook</h5>
                                                        <input type="checkbox" id="facebook" :true-value="1" :false-value="0" v-model="form.has_facebook" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.has_facebook">
                                                        <div class="form_grp p-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_facebook }">
                                                                    <Field autocomplete="off" type="text" name="footer_facebook" v-model="form.footer_facebook" placeholder="Facebook link here" rules="url|validate_url:facebook" label="facebook" />
                                                                </div>
                                                                <ErrorMessage name="footer_facebook" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label for="twitter" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                        <h5 class="large">Twitter</h5>
                                                        <input type="checkbox" id="twitter" :true-value="1" :false-value="0" v-model="form.has_twitter" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.has_twitter">
                                                        <div class="form_grp p-0">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_twitter }">
                                                                    <Field autocomplete="off" type="text" name="footer_twitter" v-model="form.footer_twitter" placeholder="Twitter link here" rules="url|validate_url:twitter" label="twitter" />
                                                                </div>
                                                                <ErrorMessage name="footer_twitter" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label for="instagram" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                        <h5 class="large">Instagram</h5>
                                                        <input type="checkbox" id="instagram" :true-value="1" :false-value="0" v-model="form.has_instagram" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.has_instagram">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_instagram }">
                                                                    <Field autocomplete="off" type="text" name="footer_instagram" v-model="form.footer_instagram" placeholder="Instagram link here" rules="url|validate_url:instagram" label="instagram" />
                                                                </div>
                                                                <ErrorMessage name="footer_instagram" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label for="linkedin" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                        <h5 class="large">Linkedin</h5>
                                                        <input type="checkbox" id="linkedin" :true-value="1" :false-value="0" v-model="form.has_linkedin" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.has_linkedin">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_linkedin }">
                                                                    <Field autocomplete="off" type="text" name="footer_linkedin" v-model="form.footer_linkedin" placeholder="Linkedin link here" rules="url|validate_url:linkedin" label="linkedin" />
                                                                </div>
                                                                <ErrorMessage name="footer_linkedin" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label for="youtube" class="switch_option capsule_btn p-0 border-0 mt-4">
                                                        <h5 class="large">Youtube</h5>
                                                        <input type="checkbox" id="youtube" :true-value="1" :false-value="0" v-model="form.has_youtube" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                    <div class="setting_wpr" v-if="form.has_youtube">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_youtube }">
                                                                    <Field autocomplete="off" type="text" name="footer_youtube" v-model="form.footer_youtube" placeholder="Youtube link here" rules="url|validate_url:youtu" label="youtube" />
                                                                </div>
                                                                <ErrorMessage name="footer_youtube" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Icons Color <a class="reset-default" @click="form.footer_textcolor = defaultForm.footer_textcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_textcolor" v-model="form.footer_textcolor" type="text" label="element color">
                                                            <color-picker v-model="form.footer_textcolor" :classes="{ 'has-error': errors.footer_textcolor }" />
                                                        </Field>
                                                        <ErrorMessage name="footer_textcolor" class="text-danger" />
                                                    </div>
                                                    <div class="group_item mt-3">
                                                        <label class="input_label">Background Color <a class="reset-default" @click="form.footer_bgcolor = defaultForm.footer_bgcolor">Reset to default</a></label>
                                                        <Field autocomplete="off" name="footer_bgcolor" v-model="form.footer_bgcolor" type="text" label="element color">
                                                            <color-picker v-model="form.footer_bgcolor" :classes="{ 'has-error': errors.footer_bgcolor }" />
                                                        </Field>
                                                        <ErrorMessage name="footer_bgcolor" class="text-danger" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="inner_box" v-show="form.display_footer" key="1">
                                                <h3 class="sub_header m-0">Footer Text</h3>
                                                <div class="color_container mt-2">
                                                    <div class="setting_wpr">
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Copyright Text</label>
                                                                <div class="field_wpr m-0" :class="{ 'has-error': errors.footer_company }">
                                                                    <Field autocomplete="off" type="text" name="footer_company" v-model="form.footer_company" :placeholder="`© ${new Date().getFullYear()} - Thrive Coach`" />
                                                                </div>
                                                                <ErrorMessage name="footer_company" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Terms Link</label>
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_terms }">
                                                                    <Field autocomplete="off" type="text" name="footer_terms" v-model="form.footer_terms" placeholder="ex: https://clientportal.com/terms" rules="url" label="term link" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage name="footer_terms" class="text-danger" />
                                                            </div>
                                                        </div>
                                                        <div class="form_grp">
                                                            <div class="group_item">
                                                                <label class="input_label">Privacy Link</label>
                                                                <div class="field_wpr has_prefix m-0" :class="{ 'has-error': errors.footer_policy }">
                                                                    <Field autocomplete="off" type="text" name="footer_policy" v-model="form.footer_policy" placeholder="ex: https://clientportal.com/privacy" rules="url" label="privacy link" />
                                                                    <span class="prefix">URL</span>
                                                                </div>
                                                                <small class="url-info">Add https:// or http:// prefix eg https://yourdomain.com</small>
                                                                <ErrorMessage name="footer_policy" class="text-danger" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="inner_box" v-show="form.display_footer" key="1">
                                                <label for="desktop-only" class="switch_option capsule_btn pb-2">
                                                    <h3 class="sub_header m-0">
                                                        Desktop Only
                                                    </h3>
                                                    <input type="checkbox" id="desktop-only" :true-value="1" :false-value="0" v-model="form.footer_desktop_only" hidden>
                                                    <div><span></span></div>
                                                </label>
                                            </div>
                                        </TransitionGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="content_wpr" v-if="step === 2">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Onboarding</h2>
                                </div>
                                <div class="color_container">
                                    <div class="soon">Coming Soon!</div>
                                </div>
                            </div>
                        </div> -->
                        <div class="content_wpr" v-if="step === 2">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Enable Chat</h2>
                                    <label for="enable-chat" class="switch_option capsule_btn p-0">
                                        <input type="checkbox" id="enable-chat" :true-value="1" :false-value="0" v-model="form.has_chatbox" hidden>
                                        <div><span></span></div>
                                    </label>
                                </div>
                                <div class="extra_note" v-if="!form.has_chatbox">Please click on the toggle to turn on chat settings</div>
                                <div v-if="form.has_chatbox">
                                    <div class="color_container mb-4">
                                        <div class="setting_wpr">
                                            <div class="form_grp p-0">
                                                <div class="group_item">
                                                    <label for="enable-chat-email-notification" class="switch_option capsule_btn p-0">
                                                        <h5 class="large">Message Notifications <span>Send New Message Notifications Via Email</span></h5>
                                                        <input type="checkbox" id="enable-chat-email-notification" :true-value="1" :false-value="0" v-model="form.portal_chat_email_notification" hidden>
                                                        <div><span></span></div>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container settings_preview mb-4">
                                        <div class="setting_wpr mt-2">
                                            <h3 class="sub_header mt-1 mb-1">
                                                Coach Settings
                                                <!-- <label for="coach_image" class="switch_option capsule_btn p-0 border-0">
                                                    <input type="checkbox" id="coach_image" :true-value="1" :false-value="0" v-model="form.chat_has_coach_image" hidden>
                                                    <div><span></span></div>
                                                </label> -->
                                            </h3>
                                            <label for="coach_new_image" class="switch_option capsule_btn">
                                                <h5 class="large">Coach Image</h5>
                                                <input type="checkbox" id="coach_new_image"  :true-value="1" :false-value="0" v-model="form.chat_has_coach_custom_image" hidden>
                                                <div><span></span></div>
                                            </label>
                                            <div class="upload_image mb-3" v-if="form.chat_has_coach_custom_image">
                                                <image-library v-model="form.chat_coach_image" image-type="coach-logo" upload-text="New Image" />
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Chat Button & Welcome Background Color<a class="reset-default" @click="form.chat_button_bg_color = defaultForm.chat_button_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_button_bg_color" v-model="form.chat_button_bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_button_bg_color" :classes="{ 'has-error': errors.chat_button_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_button_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sample_preview">
                                            <h3 class="sub_heading2 mb-3">Preview</h3>
                                            <button type="button" class="chat_btn ripple">
                                                <img v-if="form.chat_has_coach_custom_image && form.chat_coach_image" :src="form.chat_coach_image" alt="">
                                                <label v-else>{{ user.fname[0] }}{{ user.lname[0] }}</label>
                                            </button>
                                            <div class="drawer_area">
                                                <div class="chat_header">
                                                    <h2>Hi, Jason</h2>
                                                    <h4>Need help? Drop us a message and we'll get back to you soon.</h4>
                                                    <div class="coach">
                                                        <div class="coach_box">
                                                            <img v-if="form.chat_has_coach_custom_image && form.chat_coach_image" :src="form.chat_coach_image" alt="">
                                                            <div v-else class="coach_name">{{ user.fname[0] }}{{ user.lname[0] }}</div>
                                                        </div>
                                                        <h5>{{user.fname}} {{ user.lname }}<span>{{user.businessname}}</span></h5>
                                                    </div>
                                                </div>
                                                <div class="interaction">
                                                    <ul>
                                                        <li style="width: 65%;"></li>
                                                        <li style="width: 70%;"></li>
                                                        <li style="width: 55%;"></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container settings_preview mb-4">
                                        <div class="setting_wpr">
                                            <h3 class="sub_heading2">Coach Text</h3>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Coach Bubble Color <a class="reset-default" @click="form.chat_coach_bubble_bg_color = defaultForm.chat_coach_bubble_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_coach_bubble_bg_color" v-model="form.chat_coach_bubble_bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_coach_bubble_bg_color" :classes="{ 'has-error': errors.chat_coach_bubble_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_coach_bubble_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Coach Text Color <a class="reset-default" @click="form.chat_coach_bubble_text_color = defaultForm.chat_coach_bubble_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_coach_bubble_text_color" v-model="form.chat_coach_bubble_text_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_coach_bubble_text_color" :classes="{ 'has-error': errors.chat_coach_bubble_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_coach_bubble_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sample_preview">
                                            <h3 class="sub_heading2 mb-3">Preview</h3>
                                            <div class="drawer_area">
                                                <div class="chat">
                                                    <ul>
                                                        <li class="coach">Good morning! How are you feeling today?</li>
                                                        <li class="client">Morning! I'm feeling a bit overwhelmed, to be honest. Lots on my plate lately.</li>
                                                        <li class="coach">I understand. It's normal to feel that way sometimes...</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container settings_preview mb-4">
                                        <div class="setting_wpr">
                                            <h3 class="sub_heading2">Client Text</h3>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Client Text Bubble Color <a class="reset-default" @click="form.chat_client_bubble_bg_color = defaultForm.chat_client_bubble_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_client_bubble_bg_color" v-model="form.chat_client_bubble_bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_client_bubble_bg_color" :classes="{ 'has-error': errors.chat_client_bubble_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_client_bubble_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Client Text Color <a class="reset-default" @click="form.chat_client_bubble_text_color = defaultForm.chat_client_bubble_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_client_bubble_text_color" v-model="form.chat_client_bubble_text_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_client_bubble_text_color" :classes="{ 'has-error': errors.chat_client_bubble_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_client_bubble_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="sample_preview">
                                            <h3 class="sub_heading2 mb-3">Preview</h3>
                                            <div class="drawer_area">
                                                <div class="chat">
                                                    <ul>
                                                        <li class="client">Morning! I'm feeling a bit overwhelmed, to be honest. Lots on my plate lately.</li>
                                                        <li class="coach">I understand. It's normal to feel that way sometimes...</li>
                                                        <li class="client">Mostly work-related stuff. Deadlines are looming...</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="color_container mb-4">
                                        <h3 class="sub_heading2 mb-4">Desktop Chat Placement</h3>
                                        <label for="button_left" class="switch_option capsule_btn p-0 border-0">
                                            <h5 class="large">Left</h5>
                                            <Field autocomplete="off" type="radio" name="button_left" id="button_left" value="left" v-model="form.chat_placement" hidden />
                                            <div><span></span></div>
                                        </label>
                                        <label for="button_right" class="switch_option capsule_btn p-0 border-0 mt-4">
                                            <h5 class="large">Right</h5>
                                            <Field autocomplete="off" type="radio" name="button_right" id="button_right" value="right" v-model="form.chat_placement" hidden />
                                            <div><span></span></div>
                                        </label>
                                    </div>
                                    <!-- <div class="color_container mb-4">
                                        <h3 class="sub_heading2">Chat Settings</h3>
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Chat Background Color <a class="reset-default" @click="form.chat_background_color = defaultForm.chat_background_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_background_color" v-model="form.chat_background_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_background_color" :classes="{ 'has-error': errors.chat_background_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_background_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Chat Button Color <a class="reset-default" @click="form.chat_button_bg_color = defaultForm.chat_button_bg_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_button_bg_color" v-model="form.chat_button_bg_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_button_bg_color" :classes="{ 'has-error': errors.chat_button_bg_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_button_bg_color" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Chat Button Icon Color <a class="reset-default" @click="form.chat_button_text_color = defaultForm.chat_button_text_color">Reset to default</a></label>
                                                    <Field autocomplete="off" name="chat_button_text_color" v-model="form.chat_button_text_color" type="text" label="element color">
                                                        <color-picker v-model="form.chat_button_text_color" :classes="{ 'has-error': errors.chat_button_text_color }" />
                                                    </Field>
                                                    <ErrorMessage name="chat_button_text_color" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step === 3">
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Delivery Message</h2>
                                </div>
                                <div class="color_container pt-2 delivery-message">
                                    <ul class="tab_sec justify-content-start mt-3">
                                        <li @click="deliveryTab = 1" :class="{ active: deliveryTab === 1 }">Email</li>
                                        <li @click="deliveryTab = 2" :class="{ active: deliveryTab === 2 }">SMS</li>
                                    </ul>
                                    <div v-if="deliveryTab == 1">
                                        <email-component v-model="deliveryEmail" :errors="errors" :handle-default-email="handleDefaultEmail" :has-email-button="true" preview-module="client-portal" ref="email-component" is-full-view :personalize="personalize" />
                                    </div>
                                    <div v-show="deliveryTab == 2">
                                        <sms-component v-model="form.delivery_sms" media-field-name="delivery_sms_media" :media="form.delivery_sms_media" :default-sms-handler="true" :handle-default-sms="handleDefaultSms" :personalize="personalize" :update-sms-text="updateSmsText()" ref="client-portal-sms-component" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="content_wpr" v-if="step === 4">
                            <div class="section_content w-100" @click="goTo('seosection')" ref="seosetting">
                                <div class="section_header">
                                    <h2>SEO</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_title }">
                                                        <Field autocomplete="off" type="text" name="seo_title" v-model="form.seo_title" />
                                                    </div>
                                                    <ErrorMessage name="seo_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Keywords</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_keyword }">
                                                        <Field autocomplete="off" type="text" name="seo_keyword" v-model="form.seo_keyword" />
                                                    </div>
                                                    <ErrorMessage name="seo_keyword" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_desc }">
                                                        <Field autocomplete="off" type="text" name="seo_desc" v-model="form.seo_desc" />
                                                    </div>
                                                    <ErrorMessage name="seo_desc" class="text-danger" />
                                                </div>
                                            </div>
                                            <label for="seo-index" class="switch_option capsule_btn border-0">
                                                <h5 class="large">I want my page indexed by search engine</h5>
                                                <input type="checkbox" id="seo-index" :true-value="1" :false-value="0" v-model="form.seo_index" hidden>
                                                <div><span></span></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100">
                                <div class="section_header">
                                    <h2>Tracking</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <div class="form_grp mb-2">
                                                <div class="group_item">
                                                    <label class="input_label">Facebook Pixel Code</label>
                                                    <div class="field_wpr" :class="{ 'has-error': errors.fb_pixel }">
                                                        <Field autocomplete="off" type="textarea" name="fb_pixel" v-model="form.fb_pixel" label="description">
                                                            <textarea cols="10" rows="10" placeholder="Description Goes Here.." v-model="form.fb_pixel"></textarea>
                                                        </Field>
                                                    </div>
                                                    <ErrorMessage name="fb_pixel" class="text-danger" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('sharesection')" ref="sharesetting">
                                <div class="section_header">
                                    <h2>Social Share</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Title</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_title }">
                                                        <Field autocomplete="off" type="text" name="seo_share_title" v-model="form.seo_share_title" placeholder="Thrive Coach" />
                                                    </div>
                                                    <ErrorMessage name="seo_share_title" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">Description</label>
                                                    <div class="field_wpr m-0" :class="{ 'has-error': errors.seo_share_desc }">
                                                        <Field autocomplete="off" type="text" name="seo_share_desc" v-model="form.seo_share_desc" placeholder="We Are Awesome! Harnessing the Power of Technology to Advance Humanity" />
                                                    </div>
                                                    <ErrorMessage name="seo_share_desc" class="text-danger" />
                                                </div>
                                            </div>
                                            <div class="form_grp">
                                                <div class="group_item">
                                                    <label class="input_label">SEO Thumbnail</label>
                                                    <image-library v-model="form.seo_thumb" image-type="social-share" :crop="true" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="section_content w-100" @click="goTo('favsection')" ref="favsetting">
                                <div class="section_header">
                                    <h2>Favicon</h2>
                                    <button type="button" class="toggle_section" @click="toggleSection($event)"><i class="fas fa-chevron-down"></i></button>
                                </div>
                                <div class="toggle_content">
                                    <div class="color_container">
                                        <div class="setting_wpr">
                                            <image-library v-model="form.seo_fav" image-type="favicon" :crop="true" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button type="button" class="preview_btn" @click="preview = true" v-if="tab !== 'drip'">Preview</button>
                    </div>
                    <div class="action_wpr">
                        <button :disabled="updateLoader" type="button" class="btn cancel_btn" @click="closeModal()">Exit</button>
                        <button :disabled="updateLoader" class="btn save_btn"><i class="fa fa-spin fa-spinner" v-if="updateLoader"></i> {{ updateLoader ? 'Updating' : 'Update' }}</button>
                    </div>
                </Form>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step === 1">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_portal_v2" class="switch_option capsule_btn p-0 border-0" @click="handlePreview('fullPortal')">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_portal_v2" v-model="fullPortal" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <!-- <div class="small-cover-info" v-if="!form.small_cover">
                    <small>Upload a small screen cover for a better view</small>
                </div> -->
                <div class="cell">
                    <div class="content_area">
                        <div class="dashboard">
                            <div class="client_card border-0">
                                <div class="card_header pointer" :style="`background: ${form.header_bgcolor}; color: ${form.header_textcolor};`" @click="setting('hdrsetting')" ref="hdrsection">
                                    <nav>
                                        <img class="logo_img" :src="form.logo ? form.logo : require('@/assets/images/thumb/default-logo.svg')" alt="logo">
                                        <div class="mobile_bar">
                                            <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 3.58333C12.5415 1.60431 14.1458 0 16.1248 0C18.1039 0 19.7082 1.60431 19.7082 3.58333C19.7082 5.56235 18.1039 7.16667 16.1248 7.16667C14.1458 7.16667 12.5415 5.56235 12.5415 3.58333Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 16.125C12.5415 14.1459 14.1458 12.5416 16.1248 12.5416C18.1039 12.5416 19.7082 14.1459 19.7082 16.125C19.7082 18.104 18.1039 19.7083 16.1248 19.7083C14.1458 19.7083 12.5415 18.104 12.5415 16.125Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5415 28.6667C12.5415 26.6876 14.1458 25.0834 16.1248 25.0834C18.1039 25.0834 19.7082 26.6876 19.7082 28.6667C19.7082 30.6458 18.1039 32.25 16.1248 32.25C14.1458 32.25 12.5415 30.6458 12.5415 28.6667Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 3.58333C25.0835 1.60431 26.6878 0 28.6668 0C30.6459 0 32.2502 1.60431 32.2502 3.58333C32.2502 5.56235 30.6459 7.16667 28.6668 7.16667C26.6878 7.16667 25.0835 5.56235 25.0835 3.58333Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 16.125C25.0835 14.1459 26.6878 12.5416 28.6668 12.5416C30.6459 12.5416 32.2502 14.1459 32.2502 16.125C32.2502 18.104 30.6459 19.7083 28.6668 19.7083C26.6878 19.7083 25.0835 18.104 25.0835 16.125Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M25.0835 28.6667C25.0835 26.6876 26.6878 25.0834 28.6668 25.0834C30.6459 25.0834 32.2502 26.6876 32.2502 28.6667C32.2502 30.6458 30.6459 32.25 28.6668 32.25C26.6878 32.25 25.0835 30.6458 25.0835 28.6667Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 3.58333C0 1.60431 1.60431 0 3.58333 0C5.56235 0 7.16667 1.60431 7.16667 3.58333C7.16667 5.56235 5.56235 7.16667 3.58333 7.16667C1.60431 7.16667 0 5.56235 0 3.58333Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 16.125C0 14.1459 1.60431 12.5416 3.58333 12.5416C5.56235 12.5416 7.16667 14.1459 7.16667 16.125C7.16667 18.104 5.56235 19.7083 3.58333 19.7083C1.60431 19.7083 0 18.104 0 16.125Z" fill="#999999"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.6667C0 26.6876 1.60431 25.0834 3.58333 25.0834C5.56235 25.0834 7.16667 26.6876 7.16667 28.6667C7.16667 30.6458 5.56235 32.25 3.58333 32.25C1.60431 32.25 0 30.6458 0 28.6667Z" fill="#999999"/>
                                            </svg>
                                        </div>
                                    </nav>
                                    <div class="user_box" @click="accountDrop = !accountDrop">
                                        <img :src="form.author ? form.author : user.profile_pic" :alt="user.full_name">
                                        <div class="user_info">
                                            <h5 :style="`color: ${form.header_textcolor};`">{{ user.full_name }} <i class="fas fa-caret-down" :class="`${accountDrop ? 'swing' : ''}`"></i></h5>
                                        </div>
                                        <ul class="account_drop text-inherit" :class="{ active : accountDrop }">
                                            <li><i class="fas fa-home"></i> Primary Location</li>
                                            <li><i class="far fa-user"></i> Profile Info</li>
                                            <li><i class="far fa-file"></i> My Files</li>
                                            <li><i class="fas fa-sign-out-alt"></i> Logout</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="banner" v-if="form.display_cover && form.has_small_screen_cover" ref="bnrsection" @click="setting('bnrsetting')" :class="{'cover-image-sm' : form.cover_type == 1}">
                                <picture v-if="(form.cover_type == 2 || form.cover_type == 3)">
                                    <source v-if="form.small_cover != null && form.small_cover != '' && form.small_cover != 'null'" :srcset="`${form.small_cover}`" media="(max-width: 768px)">
                                    <img :src="form.small_cover ? form.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                </picture>
                                <span v-if="form.cover_type == 1" class="overlay" :style="`background-color: ${form.cover_background_color ? form.cover_background_color : form.opac_overlay_color};`"></span>
                                <span v-else-if="form.cover_type == 2 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                <span v-else-if="form.cover_type == 3 && form.has_cover_overlay" class="overlay" :style="`background-color: ${form.overlay_color}; opacity: ${(form.opac_overlay_color == 'transparent' || !form.has_cover_overlay || form.overlay_opacity == 0) ? '0': ((form.overlay_opacity / 100 ))};`"></span>
                                <div class="bnr_content">
                                    <h4 class="shadow-none" v-if="form.is_headline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.headline_setting.font_size / 3 < 20 ? 20 : form.headline_setting.font_size / 3}px; line-height: ${form.headline_setting.font_size / 3 < 20 ? 26 :(parseInt(form.headline_setting.font_size) + 20) / 3}px; font-weight: ${form.headline_setting.font_weight}; font-family: ${form.headline_setting.font_family}; color: ${form.headline_setting.font_color}; ${form.headline_setting.has_shadow ? 'text-shadow: ' + form.headline_setting.shadow_x +'px ' + form.headline_setting.shadow_y + 'px ' + form.headline_setting.shadow_blur + 'px ' + form.headline_setting.shadow_color : 'none'}`">{{ form.headline }}</h4>
                                    <h1 class="shadow-none" v-if="form.is_subheadline && (form.cover_type == 1 || form.cover_type == 2)" :style="`font-size: ${form.subheadline_setting.font_size / 3 < 11 ? 11 : form.subheadline_setting.font_size / 3}px; line-height: ${form.subheadline_setting.font_size / 3 < 11 ? 16 :(parseInt(form.subheadline_setting.font_size) + 20) / 3}px; font-weight: ${form.subheadline_setting.font_weight}; font-family: ${form.subheadline_setting.font_family}; color: ${form.subheadline_setting.font_color}; ${form.subheadline_setting.has_shadow ? 'text-shadow: ' + form.subheadline_setting.shadow_x +'px ' + form.subheadline_setting.shadow_y + 'px ' + form.subheadline_setting.shadow_blur + 'px ' + form.subheadline_setting.shadow_color : 'none'}`">{{ form.description }}</h1>
                                </div>
                            </div>
                            <div class="px-3 mb-5">
                                <ul class="widget_cards">
                                    <li v-for="(card, c) in clientPortal.widgets_sorting" :key="c">
                                        <template v-if="card === 'welcome'">
                                            <client-board type="welcome"/>
                                        </template>
                                        <template v-if="card === 'announcement'">
                                            <client-board type="announcement"/>
                                        </template>
                                        <template v-if="card === 'today_tasks'">
                                            <client-board type="tasks"/>
                                        </template>
                                    </li>
                                </ul>
                                <div ref="pintraining" @click="setting('pinsetting')">
                                    <h4 class="mt-4 mb-0" v-if="form.pinned_widget" :style="`color: ${form.announcement_title_text_color}`">Pinned Training</h4>
                                    <div class="sliderWpr" v-if="form.pinned_widget">
                                        <ul class="client_productList">
                                            <li v-for="(playbook, p) of pinnedPlaybooks" :key="p">
                                                <div class="product_card">
                                                    <img v-if="playbook.dashboard_thumb" :src="playbook.dashboard_thumb" alt="" class="product_thumb">
                                                    <img v-else src="@/assets/images/thumb/default-playbook.svg" alt="" class="product_thumb">
                                                    <div class="product_cont playbook-desc">
                                                        <h4>{{ playbook.title.length > 35 ? playbook.title.substr(0, 35)+'...' : playbook.title }}</h4>
                                                        <p>{{ playbook.description && playbook.description.length > 70 ? playbook.description.substr(0, 70)+'...' : playbook.description }}</p>
                                                        <a href="javascript:void(0);" class="primary_btn" :style="`background-color: ${form.portal_tab_bg_color != null ? form.portal_tab_bg_color : '#2f7fed'}; border-color: ${form.portal_tab_bg_color != null ? form.portal_tab_bg_color : '#2f7fed'}; color: ${form.portal_tab_text_color != null ? form.portal_tab_text_color : '#FFF'};`">Open Playbook</a>
                                                    </div>
                                                    <button type="button" class="pin custom-pin"><i class="fas fa-thumbtack"></i></button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div ref="ftrsection" @click="setting('ftrsetting')" class="mt-5">
                            <footer class="dashboard_footer" v-if="form.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                <ul>
                                    <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                    <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                    <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                    <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                    <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                </ul>
                                <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company ? form.footer_company : `${new Date().getFullYear()} - Thrive Coach` }}</h4>
                                <p>
                                    <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                    <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                    <span v-html="clientPortal.white_label"></span>
                                </p>
                            </footer>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step === 0">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_login_v2" class="switch_option capsule_btn p-0" @click="handlePreview('fullLogin')">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_login_v2" v-model="fullLogin" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="banner_area" v-if="form.login_layout == 1">
                            <div class="banner_wpr banner_cover" :class="{'cover-image-sm' : form.login_setting.cover_type == 1}" :style="`background-color: ${form.login_setting.cover ? 'transparent' : '#f5f5f5'}`" v-if="form.login_setting && form.login_setting.has_cover && form.login_setting.display_small_cover">
                                <picture v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3)">
                                    <source v-if="form.login_setting && form.login_setting.small_cover != null && form.login_setting.small_cover != '' && form.login_setting.small_cover != 'null'" :srcset="`${form.login_setting.small_cover}`" media="(max-width: 768px)">
                                    <img :src="form.login_setting && form.login_setting.small_cover ? form.login_setting.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                </picture>
                                <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                <div class="bnr_content" :class="{'py-3' : form.login_setting.cover_type == 1}" :style="`position: ${form.login_setting.cover_type == 1 ? 'relative' : 'absolute'}`">
                                    <template v-if="form.login_setting.is_headline == undefined || form.login_setting.is_headline == 1">
                                        <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                    </template>
                                    <template v-if="form.login_setting.is_subheadline == undefined || form.login_setting.is_subheadline == 1">
                                        <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 : form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                    </template>
                                </div>
                            </div>
                            <div class="login" :class="{'mt-50': (form.login_setting.has_cover == 0 || !form.login_setting.display_small_cover)}">
                                <div class="login-thumbnail" v-if="form.display_login_card">
                                    <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                        <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                    </div>
                                    <div v-if="form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed" v-html="form.thumbnail.embed"></div>
                                    <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                </div>
                                <div class="setting_wpr">
                                    <h2>{{ clientPortal.login_title }}</h2>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Robert Bacins" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Password</label>
                                            <div class="field_wpr">
                                                <input type="password" placeholder="*****" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group-col-2">
                                        <label for="remember" class="checkbox">
                                        <input type="checkbox" id="remember" hidden />
                                        <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                        </label>
                                        <div class="forgot">
                                            <a class="pointer">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                </div>
                            </div>
                            <div ref="ftrsection" @click="setting('ftrsetting')" class="mt-auto">
                                <footer class="dashboard_footer" v-if="form.login_setting.display_footer" :style="`background: ${form.footer_bgcolor};`">
                                    <ul >
                                        <li class="pointer" v-if="form.has_facebook"><a :href="form.footer_facebook" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-facebook-f"></i></a></li>
                                        <li class="pointer" v-if="form.has_twitter"><a :href="form.footer_twitter" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-twitter"></i></a></li>
                                        <li class="pointer" v-if="form.has_instagram"><a :href="form.footer_instagram" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-instagram"></i></a></li>
                                        <li class="pointer" v-if="form.has_linkedin"><a :href="form.footer_linkedin" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-linkedin"></i></a></li>
                                        <li class="pointer" v-if="form.has_youtube"><a :href="form.footer_youtube" :style="`border-color: ${form.footer_textcolor}; color: ${form.footer_textcolor};`"><i class="fab fa-youtube"></i></a></li>
                                    </ul>
                                    <h4 :style="`color: ${form.footer_textcolor};`">© {{ form.footer_company ? form.footer_company : `${new Date().getFullYear()} - Thrive Coach` }}</h4>
                                    <p>
                                        <a :href="form.footer_terms" :style="`color: ${form.footer_textcolor};`">Terms</a>
                                        <a :href="form.footer_policy" :style="`color: ${form.footer_textcolor};`">Privacy</a>
                                        <span v-html="clientPortal.white_label"></span>
                                    </p>
                                </footer>
                            </div>
                        </div>
                        <div class="banner_area2" v-else-if="form.login_layout == 2">
                            <div class="banner_wpr" :style="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.has_cover ? `background-image: url(${form.login_setting.cover ? form.login_setting.cover : require('@/assets/images/thumb/default-cover-2-3.svg')})` : ''">
                                <!-- <img :src="form.login_setting.cover" alt="banner" class="banner_img" v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.cover"> -->
                                <div class="bnrCont_wpr banner_cover2" :class="{'cover-image-sm' : form.login_setting.cover_type !== 3}" v-if="form.login_setting.has_cover">
                                    <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <template v-if="form.login_setting.is_headline == undefined || form.login_setting.is_headline == 1">
                                        <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                    </template>
                                    <template v-if="form.login_setting.is_subheadline == undefined || form.login_setting.is_subheadline == 1">
                                        <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 :form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                    </template>
                                </div>
                                <div class="login" :class="{ login_cover_bg: (form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3) && form.login_setting.cover }">
                                    <div class="login-thumbnail" v-if="form.display_login_card">
                                        <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                            <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                        </div>
                                        <div v-if="form.has_login_video && form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                        <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed" v-html="form.thumbnail.embed"></div>
                                        <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                    </div>
                                    <div class="setting_wpr">
                                        <h2>{{ clientPortal.login_title }}</h2>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Email</label>
                                                <div class="field_wpr">
                                                    <input type="text" placeholder="Robert Bacins" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form_grp">
                                            <div class="group_item">
                                                <label class="input_label">Password</label>
                                                <div class="field_wpr">
                                                    <input type="password" placeholder="*****" >
                                                </div>
                                            </div>
                                        </div>
                                        <div class="group-col-2">
                                            <label for="remember" class="checkbox">
                                            <input type="checkbox" id="remember" hidden />
                                            <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                            </label>
                                            <div class="forgot">
                                                <router-link to="/">Forgot Password?</router-link>
                                            </div>
                                        </div>
                                        <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="banner_area3" v-else-if="form.login_layout == 3">
                            <div class="banner_wpr banner_cover" :style="`background-color: ${form.login_setting.cover ? 'transparent' : '#f5f5f5'}; height: ${form.login_setting.cover_type == 1 ? '100%' : ''}`">
                                <template v-if="form.login_setting && form.login_setting.has_cover && form.login_setting.display_small_cover">
                                    <picture v-if="(form.login_setting.cover_type == 2 || form.login_setting.cover_type == 3)">
                                        <source v-if="form.login_setting && form.login_setting.small_cover != null && form.login_setting.small_cover != '' && form.login_setting.small_cover != 'null'" :srcset="`${form.login_setting.small_cover}`" media="(max-width: 768px)">
                                        <img :src="form.login_setting && form.login_setting.small_cover ? form.login_setting.small_cover : require('@/assets/images/thumb/default-cover-3-2.svg')" alt="" class="banner_img">
                                    </picture>
                                    <span v-if="form.login_setting.cover_type == 1" class="overlay" :style="`background-color: ${form.login_setting.cover_background_color ? form.login_setting.cover_background_color : form.login_setting.opac_overlay_color};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 2" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <span v-else-if="form.login_setting.cover_type == 3" class="overlay" :style="`background-color: ${form.login_setting.overlay_color}; opacity: ${(form.login_setting.opac_overlay_color == 'transparent' || !form.login_setting.has_cover_overlay || form.login_setting.overlay_opacity == 0) ? '0': ((form.login_setting.overlay_opacity / 100 ))};`"></span>
                                    <div class="bnrCont_wpr banner_cover3">
                                        <template v-if="form.login_setting.is_headline == undefined || form.login_setting.is_headline == 1">
                                            <h4 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 20 : form.login_setting.headline_setting_font_size / 3}px; line-height: ${form.login_setting.headline_setting_font_size / 3 < 20 ? 28 : (parseInt(form.login_setting.headline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.headline_setting_font_weight}; font-family: ${form.login_setting.headline_setting_font_family}; color: ${form.login_setting.headline_setting_font_color}; ${form.login_setting.headline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.headline_setting_shadow_x +'px ' + form.login_setting.headline_setting_shadow_y + 'px ' + form.login_setting.headline_setting_shadow_blur + 'px ' + form.login_setting.headline_setting_shadow_color : 'none'}`">{{ form.login_setting.headline }}</h4>
                                        </template>
                                        <template v-if="form.login_setting.is_subheadline == undefined || form.login_setting.is_subheadline == 1">
                                            <h1 class="shadow-none" v-if="form.login_setting.cover_type == 1 || form.login_setting.cover_type == 2" :style="`font-size: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 11 : form.login_setting.subheadline_setting_font_size / 3}px; line-height: ${form.login_setting.subheadline_setting_font_size / 3 < 11 ? 18 : (parseInt(form.login_setting.subheadline_setting_font_size) + 20) / 3}px; font-weight: ${form.login_setting.subheadline_setting_font_weight}; font-family: ${form.login_setting.subheadline_setting_font_family}; color: ${form.login_setting.subheadline_setting_font_color}; ${form.login_setting.subheadline_setting_has_shadow ? 'text-shadow: ' + form.login_setting.subheadline_setting_shadow_x +'px ' + form.login_setting.subheadline_setting_shadow_y + 'px ' + form.login_setting.subheadline_setting_shadow_blur + 'px ' + form.login_setting.subheadline_setting_shadow_color : 'none'}`">{{ form.login_setting.subheadline }}</h1>
                                        </template>
                                    </div>
                                </template>
                            </div>
                            <div class="login" :class="{'mt-auto' : form.login_setting.cover_type == 1}">
                                <div class="login-thumbnail" v-if="form.display_login_card">
                                    <div v-if="form.thumbnail_type == 1" class="img_wpr">
                                        <img :src="form.login_card ? form.login_card : require('@/assets/images/thumb/default-sm-cover.svg')" />
                                    </div>
                                    <div v-if="form.thumbnail_type == 3 && form.thumbnail.url" v-html="parseEmbedCode(form.thumbnail.url)"></div>
                                    <div class="video_wpr" v-if="form.thumbnail_type == 2 && form.thumbnail.embed"  v-html="form.thumbnail.embed"></div>
                                    <img src="@/assets/images/thumb/video-placeholder.svg" alt="" v-if="(form.thumbnail_type == 3 && !form.thumbnail.url) || (form.thumbnail_type == 2 && !form.thumbnail.embed)"/>
                                </div>
                                <div class="setting_wpr">
                                    <h2>{{ clientPortal.login_title }}</h2>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Email</label>
                                            <div class="field_wpr">
                                                <input type="text" placeholder="Robert Bacins" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form_grp">
                                        <div class="group_item">
                                            <label class="input_label">Password</label>
                                            <div class="field_wpr">
                                                <input type="password" placeholder="*****" >
                                            </div>
                                        </div>
                                    </div>
                                    <div class="group-col-2">
                                        <label for="remember" class="checkbox">
                                            <input type="checkbox" id="remember" hidden />
                                            <span class="mr-1"><i class="fas fa-check"></i></span> Remember Me
                                        </label>
                                        <div class="forgot">
                                            <a href="javascript:void(0);">Forgot Password?</a>
                                        </div>
                                    </div>
                                    <button type="button" class="primary_btn mt-4" :style="`background: ${form.btn_color ? form.btn_color : '#2F7FED'}; border-color: ${form.btn_color ? form.btn_color : '#2F7FED'}; color: ${form.btn_txtcolor ? form.btn_txtcolor : '#FFF'};`">{{ form.btn_text }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step === 2 && form.has_chatbox">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="cell">
                    <div class="content_area">
                        <div class="chatbox_wpr" :style="`background: ${form.chat_background_color};`" :class="{'show' : chat}" v-if="form.has_chatbox">
                            <!-- <button class="chat_close" @click="chat = false" :style="`background: ${form.chat_button_bg_color}; color: ${form.chat_button_text_color};`"><i class="fas fa-angle-down"></i></button> -->
                            <button class="chat_close" @click="chat = false"><i class="fas fa-times"></i></button>
                            <div class="chat_header">
                                <h2>Hi, Jason</h2>
                                <h4>Need help? Drop us a message and we'll get back to you soon.</h4>
                                <div class="coach">
                                    <div class="coach_box">
                                        <img v-if="form.chat_has_coach_custom_image && form.chat_coach_image" :src="form.chat_coach_image" alt="">
                                        <div v-else class="coach_name">{{ user.fname[0] }}{{ user.lname[0] }}</div>
                                    </div>
                                    <h5>{{user.fname}} {{ user.lname }}<span>{{user.businessname}}</span></h5>
                                </div>
                            </div>
                            <div class="chat">
                                <div class="timeline"><span>Yesterday</span></div>
                                <div class="chat_user">
                                    <div class="coach_box">
                                        <img v-if="form.chat_has_coach_custom_image && form.chat_coach_image" :src="form.chat_coach_image" alt="">
                                        <div v-else class="coach_name">{{ user.fname[0] }}{{ user.lname[0] }}</div>
                                    </div>
                                    <div class="msg">
                                        <p>Good morning! How are you feeling today?</p>
                                        <span>14h ago</span>
                                    </div>
                                    <span class="option">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <ul>
                                            <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                            <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                            <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="chat_client">
                                    <span class="option">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <ul>
                                            <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                            <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                            <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                        </ul>
                                    </span>
                                    <div class="msg">
                                        <p>Morning! I'm feeling a bit overwhelmed, to be honest. Lots on my plate lately.</p>
                                        <span>14h ago</span>
                                    </div>
                                    <img src="@/assets/images/default-avatar.svg" alt="">
                                </div>
                                <div class="chat_user">
                                    <div class="coach_box">
                                        <img v-if="form.chat_has_coach_custom_image && form.chat_coach_image" :src="form.chat_coach_image" alt="">
                                        <div v-else class="coach_name">BD</div>
                                    </div>
                                    <div class="msg">
                                        <p>I understand. It's normal to feel that way sometimes, especially when things get busy. What's been weighing on you the most?</p>
                                        <span>14h ago</span>
                                    </div>
                                    <span class="option">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <ul>
                                            <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                            <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                            <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                        </ul>
                                    </span>
                                </div>
                                <div class="chat_client">
                                    <span class="option">
                                        <i class="fas fa-ellipsis-v"></i>
                                        <ul>
                                            <li><label>Created :</label><span>May 10, 2022 | 8:00:20 am</span></li>
                                            <li><label>Sent :</label><span>May 10, 2022 | 8:00:40 am</span></li>
                                            <li><label>Delivered :</label><span>May 10, 2022 | 8:01:00 am</span></li>
                                        </ul>
                                    </span>
                                    <div class="msg">
                                        <p>Mostly work-related stuff. Deadlines are looming, and I'm struggling to stay organized.</p>
                                        <span>14h ago</span>
                                    </div>
                                    <img src="@/assets/images/default-avatar.svg" alt="">
                                </div>
                            </div>
                        </div>
                        <!-- <button v-if="form.has_chatbox" type="button" class="chat_btn pointer" @click="chat = !chat" :style="`background: ${form.chat_button_bg_color}; color: ${form.chat_button_text_color}; float: ${form.chat_placement};`"> -->
                        <button v-if="form.has_chatbox" type="button" class="chat_btn pointer" @click="chat = !chat">
                            <i class="fas fa-comments"></i>
                            <label class="ml-2">CHAT</label>
                        </button>
                    </div>
                </div>
            </div>

            <div class="preview_content" :class="{ show : preview }" v-if="step === 4">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                    </div>
                </div>
                <div class="area_wpr">
                    <h3 class="sub_header" ref="seosection" @click="setting('seosetting')">Search Engine Listing</h3>
                    <div class="google_ranking">
                        <h6>About 5,29,000 results(0.36 seconds)</h6>
                        <h4>{{form.seo_title}}</h4>
                        <p>{{form.seo_desc}}</p>
                    </div>
                </div>
                <div class="area_wpr" ref="sharesection" @click="setting('sharesetting')">
                    <h3 class="sub_header">Social Share</h3>
                    <div class="seo_card">
                        <img :src="validLink(form.seo_thumb) ? form.seo_thumb : require('@/assets/images/thumb/default-step-cover.svg')" alt="">
                        <div class="seo_info">
                            <h3>{{ form.seo_share_title ? form.seo_share_title : 'Optimize Your Metabolism In 30 Days' }}</h3>
                            <p>{{ form.seo_share_desc ? form.seo_share_desc : 'It is easy to speed upa slow metabolism in as little as 30 days using this proven technique.' }}</p>
                        </div>
                    </div>
                </div>
                <div class="area_wpr" ref="favsection" @click="setting('favsetting')">
                    <h3 class="sub_header">Favicon</h3>
                    <div class="browser_layout">
                        <ul class="browser_tabs">
                            <li>
                                <img :src="form.seo_fav ? form.seo_fav : (env.VUE_APP_NAME ? require('@/assets/images/'+env.VUE_APP_NAME+'/logo.svg') : require('@/assets/images/logo.png'))" alt="">
                                <h6>{{ form.seo_title ? (form.seo_title.length > 15 ? form.seo_title.substr(0, 15)+'...' : form.seo_title) : 'Thrive Coach' }}</h6>
                                <i class="fas fa-times"></i>
                            </li>
                            <li><i class="fas fa-plus"></i></li>
                        </ul>
                        <div class="browser_info">
                            <i class="fas fa-arrow-left"></i>
                            <i class="fas fa-arrow-right"></i>
                            <i class="fas fa-redo"></i>
                            <div class="browser_search">
                                <i class="fas fa-lock"></i>
                                <p>https://thrivecoach.io</p>
                                <i class="far fa-star ml-auto"></i>
                            </div>
                            <img src="@/assets/images/default-avatar.svg" class="user" alt=""/>
                            <i class="fas fa-ellipsis-v"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="preview_content" :class="{ show : preview }" v-if="step === 3">
                <div class="header">
                    <div class="section_header m-0">
                        <button class="close_btn" @click="preview = !preview"><i class="fas fa-long-arrow-alt-right"></i></button>
                        <h2>Preview</h2>
                        <label for="preview_msg" class="switch_option capsule_btn p-0 border-0">
                            <h5 class="mr-3">Full View</h5>
                            <input type="checkbox" id="preview_msg" v-model="previewMsg" hidden>
                            <div><span></span></div>
                        </label>
                    </div>
                </div>
                <ul class="alt_prev">
                    <li @click="deliveryTab = 1" :class="{ active: deliveryTab === 1 }">E-mail</li>
                    <li @click="deliveryTab = 2" :class="{ active: deliveryTab === 2 }">SMS</li>
                </ul>
                <div class="cell">
                    <div class="content_area" v-if="deliveryTab === 1">
                        <div class="msg_preview">
                            <h2>{{ replaceVariables(deliveryEmail.subject) }}</h2>
                            <div class="sender_info">
                                <img src="@/assets/images/default-avatar.svg" alt="">
                                <div class="info">
                                    <h5>Thrive Coach</h5>
                                    <h6><span>To:</span>{{ user.full_name }}</h6>
                                </div>
                            </div>
                            <div class="email-logo" v-if="deliveryEmail.show_email_logo">
                                <img :src="deliveryEmail.email_logo ? deliveryEmail.email_logo : require('@/assets/images/thumb/default-logo.svg')" />
                            </div>
                            <div class="redactor-styles redactor-in redactor-in-0 p-0 email-preview" v-html="replaceVariables(deliveryEmail.email, deliveryEmail.email_button_bg_color, deliveryEmail.email_button_text_color)"></div>
                            <div v-html="generateSignature(deliveryEmail.is_signature, deliveryEmail.signature_id)"></div>
                        </div>
                        <div class="msgFooter text-center mb-3">
                            <p>
                                <template v-if="membership.has_white_label && whiteLabel.has_label == 1">
                                    <a v-if="whiteLabel.has_link == 1" style="text-decoration: none; color:#525252;" :href="whiteLabel.link"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                    <a v-else style="text-decoration: none; color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by {{ whiteLabel.powered_by }}</a>
                                </template>
                                <a v-else style="text-decoration: none;  color:#525252;" href="https://superfitcoaching.com"><i class="fas fa-bolt"></i> Powered by Thrive Coach</a>
                            </p>
                            <p style="text-decoration: none; color:#525252;">{{ user.businessname }}</p>
                            <p><a style="color: #525252;" href="javascript:void(0);">Unsubscribe</a></p>
                        </div>
                    </div>
                    <div class="content_area" v-if="deliveryTab === 2">
                        <div class="sms_preview">
                            <pre>
                                <div class="redactor-styles redactor-in redactor-in-0 p-0" v-html="replaceVariables(clientPortalSmsText)"></div>
                            </pre>
                        </div>
                    </div>
                </div>
            </div>
            <portal-preview  v-model="fullPortal" />
            <login-preview v-model="fullLogin" />
            <message-preview v-model="previewMsg"/>
            <video-tutorial v-model="tutorial" :tutorial-video="tutorialVideo"></video-tutorial>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { copyText } from 'vue3-clipboard'
    import { Field, Form, ErrorMessage } from 'vee-validate'
    import { mapState, mapActions, mapGetters } from 'vuex'

    const LoginPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/LoginPreview'))
    const PortalPreview = defineAsyncComponent(() => import('@/pages/client-portal/components/PortalPreview'))
    const MessagePreview = defineAsyncComponent(() => import('@/pages/client-portal/components/MessagePreview'))
    const ClientBoard = defineAsyncComponent(() => import('@/pages/client-portal/components/ClientBoard'))

    import Toastr from '@/utils/Toastr'
    import VideoParser from '@/utils/VideoParser'
    import constants from '@/utils/Constant'

    export default {
        name: 'Client Portal Setting',

        data () {
            return {
                tab: 'portal',
                step: 0,
                deliveryTab: 1,
                fullPortal: false,
                fullLogin: false,
                preview: true,
                previewLoader: false,
                habitScore: true,
                progresScore: true,
                portaLogins: true,
                referrals: true,
                whiteLabel: {},
                previewMsg: false,
                accountDrop: false,
                edit_classroom: false,
                edit_calendar: false,
                edit_chart: false,
                edit_playbooks: false,
                edit_pages: false,
                edit_workouts: false,
                edit_forms: false,
                edit_files: false,
                edit_morning: false,
                edit_afternoon: false,
                edit_evening: false,
                toggleAll: 1,
                stepContentResetWatcher: 0,
                form: {
                    portal_url: '',
                    portal_tab_bg_color: '#2C3E50',
                    portal_tab_text_color: '#FFF',
                    has_announcement: 0,
                    announcement_bg_color: '#D4D4D4',
                    announcement_title_text_color: '#000',
                    chart_settings: {
                        card_accent_color: '#2F7EED',
                        graph_accent_color: '#2F7EED',
                    },
                    announcement_text_color: '#393B3F',
                    email_button_bg_color: '#2C3E50',
                    email_button_text_color: '#FFF',
                    announcement_message: '',
                    has_announcement_link: 0,
                    announcement_title: '',
                    announcement_link_text: '',
                    announcement_link: '',
                    has_welcome_video: 0,
                    welcome_video_type: 1,
                    welcome_video_title: '',
                    welcome_video_link: '',
                    welcome_video_embed_code: '',
                    classroom_tab_name: 'Classroom',
                    classroom_tab : 1,
                    playbook_tab: 1,
                    page_tab: 1,
                    form_tab: 1,
                    file_tab: 1,
                    playbook_tab_name: 'Playbooks',
                    nav_pages: 'Pages',
                    workout_tab_name: 'Workouts',
                    form_tab_name: 'Forms',
                    file_tab_name: 'Files',
                    calendar_tab_name: 'Calendar',
                    calendar_tab: 1,
                    progress_tab_name: 'Tracking',
                    calendar_settings: {
                        event_color: '#ffe8c1',
                        text_color: '#121525',
                        morning_text_color: '#121525',
                        morning_icon_color: '#ff7553',
                        afternoon_text_color: '#121525',
                        afternoon_icon_color: '#f2a31d',
                        evening_text_color: '#121525',
                        evening_icon_color: '#548fe3',
                        event_tag_color: '#ecf4ff',
                        event_tag_text_color: '#2f7eed',
                        event_cta_color: '#2f7eed',
                        morning_text: 'Your Morning Activities',
                        afternoon_text: 'Your Afternoon Activities',
                        evening_text: 'Your Evening Activities',
                    },
                    nav_progress: 'Charts',
                    progress_tab: 1,
                    workout_tab: 1,
                    habit_score_color: '#2C3E50',
                    progress_score_color: '#2C3E50',
                    login_score_color: '#2C3E50',
                    referral_score_color: '#2C3E50',
                    btn_color: '#323844',
                    btn_txtcolor: '#ffffff',
                    login_layout: 1,
                    login_title: '',
                    btn_text: '',
                    display_login_card: 0,
                    thumbnail_type: '',
                    login_card: '',
                    has_login_video: 0,
                    thumbnail: {},
                    chat_client_bubble_bg_color: '#e7f1ff',
                    chat_client_bubble_text_color: '#121525',
                    chat_coach_bubble_bg_color: '#f3f3f3',
                    chat_coach_bubble_text_color: '#121525',
                    chat_has_coach_image: 0,
                    chat_has_coach_custom_image: 0,
                    chat_button_bg_color: '#2c3e50',
                    chat_button_text_color: '#FFF',
                    chat_coach_image: '',
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    has_linkedin: 0,
                    has_youtube: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_linkedin: '',
                    footer_youtube: '',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    footer_textcolor: '#2C3E50',
                    footer_bgcolor: '#f5f5f5',
                    delivery_sms: '',
                    seo_fav: '',
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    seo_title: 'Thrive Coach - The marketing automation platform for coaches',
                    seo_keyword: '',
                    seo_desc: 'Wherever you go, Thrive Coach is there acting as your personal assistant. Thrive Coach notifies you of everything going on in your business from new leads, form',
                    seo_index: '',
                    fb_pixel: '',
                    chat_background_color: '#f2f5fc',
                    has_chatbox: 1,
                    portal_chat_email_notification: 1,
                    has_footer: 1,
                    chat_placement: 'right',
                    display_footer: 1,
                    footer_desktop_only: 0,
                    pinned_widget: 1,
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    cover: '',
                    has_small_screen_cover: 0,
                    small_cover: '',
                    has_cover_overlay: 0,
                    overlay_opacity: 20,
                    cover_type: 1,
                    headline: 'WELCOME TO',
                    description: 'YOUR CLIENT PORTAL',
                    overlay_color: '#2c3e50',
                    opac_overlay_color: '20',
                    cover_background_color: '#2F7FED',
                    display_cover: 1,
                    activity_widget: 1,
                    delivery_sms_media: [],
                    company_branding: '',
                    header_bgcolor: '#ffffff',
                    header_textcolor: '#151111',
                    logo: '',
                    author: '',
                    login_setting: {
                        has_cover: 1,
                        cover_type: 2,
                        headline: 'WELCOME TO',
                        subheadline: 'YOUR CLIENT PORTAL',
                        headline_setting_font_family: 'Inter',
                        headline_setting_font_weight: 500,
                        headline_setting_font_size: 50,
                        headline_setting_font_color: '#FFF',
                        headline_setting_has_shadow: 0,
                        headline_setting_shadow_x: 0,
                        headline_setting_shadow_y: 4,
                        headline_setting_shadow_blur: 4,
                        headline_setting_shadow_color: '#2F7FED',
                        subheadline_setting_font_family: 'Inter',
                        subheadline_setting_font_weight: 500,
                        subheadline_setting_font_size: 25,
                        subheadline_setting_font_color: '#FFF',
                        subheadline_setting_has_shadow: 0,
                        subheadline_setting_shadow_x: 0,
                        subheadline_setting_shadow_y: 4,
                        subheadline_setting_shadow_blur: 4,
                        subheadline_setting_shadow_color: '#2F7FED',
                    },
                    calendar_email_notification: 1,
                },
                defaultForm: {
                    portal_url: '',
                    portal_tab_bg_color: '#2C3E50',
                    portal_tab_text_color: '#FFF',
                    has_announcement: 0,
                    announcement_bg_color: '#D4D4D4',
                    announcement_text_color: '#393B3F',
                    email_button_bg_color: '#2C3E50',
                    email_button_text_color: '#FFF',
                    announcement_title_text_color: '#000',
                    chart_settings: {
                        card_accent_color: '#2F7EED',
                        graph_accent_color: '#2F7EED',
                    },
                    announcement_message: '<p></p>',
                    has_announcement_link: 0,
                    announcement_title: '',
                    announcement_link_text: '',
                    announcement_link: '',
                    has_welcome_video: 0,
                    welcome_video_type: 1,
                    welcome_video_title: '',
                    welcome_video_link: '',
                    welcome_video_embed_code: '',
                    classroom_tab_name: 'Classroom',
                    classroom_tab : 1,
                    playbook_tab: 1,
                    page_tab: 1,
                    form_tab: 1,
                    file_tab: 1,
                    playbook_tab_name: 'Playbooks',
                    nav_pages: 'Pages',
                    workout_tab_name: 'Workouts',
                    form_tab_name: 'Forms',
                    file_tab_name: 'Files',
                    calendar_tab_name: 'Calendar',
                    calendar_tab: 1,
                    progress_tab_name: 'Tracking',
                    calendar_settings: {
                        event_color: '#ffe8c1',
                        text_color: '#121525',
                        morning_text_color: '#121525',
                        morning_icon_color: '#ff7553',
                        afternoon_text_color: '#121525',
                        afternoon_icon_color: '#f2a31d',
                        evening_text_color: '#121525',
                        evening_icon_color: '#548fe3',
                        event_tag_color: '#ecf4ff',
                        event_tag_text_color: '#2f7eed',
                        event_cta_color: '#2f7eed',
                        morning_text: 'Your Morning Activities',
                        afternoon_text: 'Your Afternoon Activities',
                        evening_text: 'Your Evening Activities',
                    },
                    nav_progress: 'Charts',
                    progress_tab: 1,
                    workout_tab: 1,
                    habit_score_color: '#2C3E50',
                    progress_score_color: '#2C3E50',
                    login_score_color: '#2C3E50',
                    referral_score_color: '#2C3E50',
                    btn_color: '#323844',
                    btn_txtcolor: '#ffffff',
                    login_layout: 1,
                    login_title: '',
                    btn_text: '',
                    display_login_card: 0,
                    thumbnail_type: '',
                    login_card: '',
                    has_login_video: 0,
                    thumbnail: {},
                    chat_client_bubble_bg_color: '#e7f1ff',
                    chat_client_bubble_text_color: '#121525',
                    chat_coach_bubble_bg_color: '#f3f3f3',
                    chat_coach_bubble_text_color: '#121525',
                    chat_has_coach_image: 0,
                    chat_has_coach_custom_image: 0,
                    chat_button_bg_color: '#2c3e50',
                    chat_button_text_color: '#FFF',
                    chat_coach_image: '',
                    has_facebook: 0,
                    has_twitter: 0,
                    has_instagram: 0,
                    has_linkedin: 0,
                    has_youtube: 0,
                    footer_facebook: '',
                    footer_twitter: '',
                    footer_instagram: '',
                    footer_linkedin: '',
                    footer_youtube: '',
                    footer_company: '',
                    footer_terms: '',
                    footer_policy: '',
                    footer_textcolor: '#2C3E50',
                    footer_bgcolor: '#f5f5f5',
                    delivery_sms: '',
                    seo_fav: '',
                    seo_share_title: '',
                    seo_share_desc: '',
                    seo_thumb: '',
                    seo_title: 'Thrive Coach - The marketing automation platform for coaches',
                    seo_keyword: '',
                    seo_desc: 'Wherever you go, Thrive Coach is there acting as your personal assistant. Thrive Coach notifies you of everything going on in your business from new leads, form',
                    seo_index: '',
                    fb_pixel: '',
                    chat_background_color: '#f2f5fc',
                    has_chatbox: 1,
                    portal_chat_email_notification: 1,
                    has_footer: 1,
                    chat_placement: 'right',
                    display_footer: 1,
                    footer_desktop_only: 0,
                    pinned_widget: 1,
                    headline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    subheadline_setting: {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: 'rgba(0, 0, 0, 0.25)',
                    },
                    cover: '',
                    has_small_screen_cover: 0,
                    small_cover: '',
                    has_cover_overlay: 0,
                    overlay_opacity: 20,
                    cover_type: 1,
                    headline: 'WELCOME TO',
                    description: 'YOUR CLIENT PORTAL',
                    overlay_color: '#2c3e50',
                    opac_overlay_color: '20',
                    cover_background_color: '#2F7FED',
                    display_cover: 1,
                    activity_widget: 1,
                    delivery_sms_media: [],
                    company_branding: '',
                    header_bgcolor: '#ffffff',
                    header_textcolor: '#151111',
                    logo: '',
                    author: '',
                    login_setting: {
                        has_cover: 1,
                        cover_type: 2,
                        headline: 'WELCOME TO',
                        subheadline: 'YOUR CLIENT PORTAL',
                        headline_setting_font_family: 'Inter',
                        headline_setting_font_weight: 500,
                        headline_setting_font_size: 50,
                        headline_setting_font_color: '#FFF',
                        headline_setting_has_shadow: 0,
                        headline_setting_shadow_x: 0,
                        headline_setting_shadow_y: 4,
                        headline_setting_shadow_blur: 4,
                        headline_setting_shadow_color: '#2F7FED',
                        subheadline_setting_font_family: 'Inter',
                        subheadline_setting_font_weight: 500,
                        subheadline_setting_font_size: 25,
                        subheadline_setting_font_color: '#FFF',
                        subheadline_setting_has_shadow: 0,
                        subheadline_setting_shadow_x: 0,
                        subheadline_setting_shadow_y: 4,
                        subheadline_setting_shadow_blur: 4,
                        subheadline_setting_shadow_color: '#2F7FED',
                    },
                    calendar_email_notification: 1,
                },
                deliveryEmail: {
                    defaultHandler: true,
                    subject: '',
                    email: '',
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                },
                loginHeadlineSetting: false,
                loginSubheadlineSetting: false,
                headlineSetting: false,
                subheadlineSetting: false,
                chat: true,
                env: {},
                delivery_sms_media: [],
                clientPortalSmsText: '',
                personalize: constants.personalize_playbook_dashboard,
                tutorial:false,
                tutorialVideo: '',
            };
        },

        props: {
            modelValue: Boolean,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            Field,
            ErrorMessage,
            PortalPreview,
            LoginPreview,
            MessagePreview,
            ClientBoard,
        },

        watch: {
            modelValue (value) {
                if (value) {
                    const vm = this;
                    // vm.editorDefaultValue = vm.form.announcement_message;
                    vm.resetForm();
                    document.body.classList.add('modal-open');
                    setTimeout(function () {
                        vm.loaded = true;
                        vm.stepContentResetWatcher += 1;
                       
                    }, 2000);
                } else {
                    document.body.classList.remove('modal-open');
                }
            },

            delivery_sms_media (media) {
                const vm = this;

                vm.form.delivery_sms_media = media;
            },

            step(val){
                const vm = this;
                vm.toggleAll = 1;
            }
        },

        computed: {
            ...mapState({
                clientPortal: state => state.clientPortalModule.clientPortal,
                updateLoader: state => state.clientPortalModule.updateLoader,
                pinnedPlaybooks: state => state.clientPortalModule.pinnedPlaybooks,
                tabRenameLoader: state => state.clientPortalModule.tabRenameLoader,
                calendarTitleLoader: state => state.clientPortalModule.calendarTitleLoader,
                user: state => state.authModule.user,
                membership: state => state.authModule.membership,
                fontFamily: state => state.commonModule.fontFamily,
                fontWeight: state => state.commonModule.fontWeight,
            }),

            ...mapGetters({
                replaceVariables: 'commonModule/REPLACE_VARIABLES',
                generateSignature: 'commonModule/GENERATE_SIGNATURE',
            }),
        },

        mounted () {
            const vm = this;

            vm.resetForm();
            vm.whiteLabel = vm.user.white_label ? JSON.parse(JSON.stringify(vm.user.white_label)) : {};
            vm.env = process.env;

            window.addEventListener('resize', vm.resizeEventHandler);
            vm.resizeEventHandler();
        },

        unmounted() {
            const vm = this;
            window.removeEventListener('resize', vm.resizeEventHandler);
        },

        methods: {
            ...mapActions({
                updatePortalSetting: 'clientPortalModule/updatePortalSetting',
                renameTab: 'clientPortalModule/renameTab',
                updateCalendarTitle: 'clientPortalModule/updateCalendarTitle',
            }),

            closeModal () {
                const vm = this;
                vm.$emit('update:modelValue', false);
                vm.edit_classroom = false;
                vm.edit_calendar = false;
                vm.edit_chart = false;
                vm.edit_playbooks = false;
                vm.edit_pages = false;
                vm.edit_workouts = false;
                vm.edit_forms = false;
                vm.edit_files = false;
            },

            switchTab (tab) {
                const vm = this;

                vm.tab = tab;
            },

            resetForm () {
                const vm = this;
                let headline_setting = vm.clientPortal.headline_setting ? JSON.parse(vm.clientPortal.headline_setting) : {};
                let subheadline_setting = vm.clientPortal.subheadline_setting ? JSON.parse(vm.clientPortal.subheadline_setting) : {};
                let login_setting = (vm.clientPortal.login_setting && typeof JSON.parse(vm.clientPortal.login_setting) == 'object') ? JSON.parse(vm.clientPortal.login_setting) : {};
                login_setting.has_cover = login_setting.has_cover ? login_setting.has_cover : 0;

                if (login_setting.display_footer == undefined) {
                    login_setting.display_footer = 1;
                }

                if (headline_setting.font_size == undefined) {
                    headline_setting = {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 50,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    };
                }

                if (subheadline_setting.font_size == undefined) {
                    subheadline_setting = {
                        font_family: 'Inter',
                        font_weight: 500,
                        font_size: 25,
                        font_color: '#2C3E50',
                        has_shadow: 0,
                        shadow_x: 0,
                        shadow_y: 4,
                        shadow_blur: 4,
                        shadow_color: '#2F7FED',
                    };
                }

                if (login_setting.headline_setting_font_size == undefined) {
                    login_setting = {
                        has_cover: 1,
                        cover_type: 2,
                        headline: 'WELCOME TO',
                        subheadline: 'YOUR CLIENT PORTAL',
                        headline_setting_font_family: 'Inter',
                        headline_setting_font_weight: 500,
                        headline_setting_font_size: 50,
                        headline_setting_font_color: '#FFF',
                        headline_setting_has_shadow: 0,
                        headline_setting_shadow_x: 0,
                        headline_setting_shadow_y: 4,
                        headline_setting_shadow_blur: 4,
                        headline_setting_shadow_color: '#2F7FED',
                        subheadline_setting_font_family: 'Inter',
                        subheadline_setting_font_weight: 500,
                        subheadline_setting_font_size: 25,
                        subheadline_setting_font_color: '#FFF',
                        subheadline_setting_has_shadow: 0,
                        subheadline_setting_shadow_x: 0,
                        subheadline_setting_shadow_y: 4,
                        subheadline_setting_shadow_blur: 4,
                        subheadline_setting_shadow_color: '#2F7FED',
                    };
                }

                login_setting.display_small_cover = login_setting.small_cover ? 1 : 0;

                const calendarSettings = {
                                            event_color: '#ffe8c1',
                                            text_color: '#121525',
                                            morning_text_color: '#121525',
                                            morning_icon_color: '#ff7553',
                                            afternoon_text_color: '#121525',
                                            afternoon_icon_color: '#f2a31d',
                                            evening_text_color: '#121525',
                                            evening_icon_color: '#548fe3',
                                            event_tag_color: '#ecf4ff',
                                            event_tag_text_color: '#2f7eed',
                                            event_cta_color: '#2f7eed',
                                            morning_text: 'Your Morning Activities',
                                            afternoon_text: 'Your Afternoon Activities',
                                            evening_text: 'Your Evening Activities',
                                        };

                if (!vm.clientPortal.announcement_message) {
                    vm.clientPortal.announcement_message = '<p></p>';
                }
                vm.form = {
                    headline_setting,
                    subheadline_setting,
                    login_setting,
                    id: vm.clientPortal.id ? vm.clientPortal.id : 0,
                    portal_url: vm.clientPortal.portal_url ? vm.clientPortal.portal_url : '',
                    portal_tab_bg_color: vm.clientPortal.portal_tab_bg_color ? vm.clientPortal.portal_tab_bg_color : '#2F80ED',
                    portal_tab_text_color: vm.clientPortal.portal_tab_text_color ? vm.clientPortal.portal_tab_text_color : '#FFF',
                    has_announcement: vm.clientPortal.has_announcement ? vm.clientPortal.has_announcement : 0,
                    announcement_title: vm.clientPortal.announcement_title ? vm.clientPortal.announcement_title : 'New Announcement',
                    announcement_bg_color: vm.clientPortal.announcement_bg_color ? vm.clientPortal.announcement_bg_color : '#95d7ef66',
                    announcement_message: vm.clientPortal.announcement_message ? vm.clientPortal.announcement_message : '<p></p>',
                    has_announcement_link: vm.clientPortal.has_announcement_link ? vm.clientPortal.has_announcement_link : 0,
                    announcement_link_text: vm.clientPortal.announcement_link_text ? vm.clientPortal.announcement_link_text : '',
                    announcement_link: vm.clientPortal.announcement_link ? vm.clientPortal.announcement_link : '',
                    announcement_text_color: vm.clientPortal.announcement_text_color ? vm.clientPortal.announcement_text_color : '#000',
                    announcement_link_color: vm.clientPortal.announcement_link_color ? vm.clientPortal.announcement_link_color : '#2F7FED',
                    announcement_title_text_color: vm.clientPortal.announcement_title_text_color ? vm.clientPortal.announcement_title_text_color : '#000',
                    chart_settings: vm.clientPortal.chart_settings ? vm.clientPortal.chart_settings : { card_accent_color: '#2F7EED', graph_accent_color: '#2F7EED' },
                    has_welcome_video: vm.clientPortal.has_welcome_video ? vm.clientPortal.has_welcome_video : 0,
                    welcome_video_type: vm.clientPortal.welcome_video_type ? vm.clientPortal.welcome_video_type : 1,
                    welcome_video_title: vm.clientPortal.welcome_video_title ? vm.clientPortal.welcome_video_title : '',
                    welcome_title_text_color: vm.clientPortal.welcome_title_text_color ? vm.clientPortal.welcome_title_text_color : '#000',
                    welcome_video_link: vm.clientPortal.welcome_video_link ? vm.clientPortal.welcome_video_link : '',
                    welcome_video_embed_code: vm.clientPortal.welcome_video_embed_code ? vm.clientPortal.welcome_video_embed_code : '',
                    classroom_tab: vm.clientPortal.classroom_tab ? vm.clientPortal.classroom_tab : 0,
                    playbook_tab: vm.clientPortal.playbook_tab ? vm.clientPortal.playbook_tab : 0,
                    page_tab: vm.clientPortal.page_tab ? vm.clientPortal.page_tab : 0,
                    form_tab: vm.clientPortal.form_tab ? vm.clientPortal.form_tab : 0,
                    calendar_tab: vm.clientPortal.calendar_tab ? vm.clientPortal.calendar_tab : 0,
                    progress_tab: vm.clientPortal.progress_tab ? vm.clientPortal.progress_tab : 0,
                    workout_tab: vm.clientPortal.workout_tab ? vm.clientPortal.workout_tab : 0,
                    file_tab: vm.clientPortal.file_tab ? vm.clientPortal.file_tab : 0,
                    habit_score_color: vm.clientPortal.habit_score_color ? vm.clientPortal.habit_score_color : '#2F7FED',
                    progress_score_color: vm.clientPortal.progress_score_color ? vm.clientPortal.progress_score_color : '#F2A31D',
                    login_score_color: vm.clientPortal.login_score_color ? vm.clientPortal.login_score_color : '#95D7EF',
                    referral_score_color: vm.clientPortal.referral_score_color ? vm.clientPortal.referral_score_color : '#999999',
                    btn_color: vm.clientPortal.btn_color ? vm.clientPortal.btn_color : '#2F7FED',
                    btn_txtcolor: vm.clientPortal.btn_txtcolor ? vm.clientPortal.btn_txtcolor : '#FFF',
                    login_layout: vm.clientPortal.login_layout ? vm.clientPortal.login_layout : 1,
                    login_title: vm.clientPortal.login_title ? vm.clientPortal.login_title : 'Secure Client Portal',
                    btn_text: vm.clientPortal.btn_text ? vm.clientPortal.btn_text : 'Sign In',
                    display_login_card: vm.clientPortal.display_login_card ? vm.clientPortal.display_login_card : 0,
                    thumbnail_type: vm.clientPortal.thumbnail_type ? vm.clientPortal.thumbnail_type : 1,
                    login_card: vm.clientPortal.login_card ? vm.clientPortal.login_card : '',
                    has_login_video: vm.clientPortal.has_login_video ? vm.clientPortal.has_login_video : 0,
                    thumbnail: (vm.clientPortal.thumbnail && typeof JSON.parse(vm.clientPortal.thumbnail) == 'object' && Object.values(JSON.parse(vm.clientPortal.thumbnail)).length) ? JSON.parse(vm.clientPortal.thumbnail) : {},
                    chat_client_bubble_bg_color: vm.clientPortal.chat_client_bubble_bg_color ? vm.clientPortal.chat_client_bubble_bg_color : '#e7f1ff',
                    chat_client_bubble_text_color: vm.clientPortal.chat_client_bubble_text_color ? vm.clientPortal.chat_client_bubble_text_color : '#121525',
                    chat_coach_bubble_bg_color: vm.clientPortal.chat_coach_bubble_bg_color ? vm.clientPortal.chat_coach_bubble_bg_color : '#f3f3f3',
                    chat_coach_bubble_text_color: vm.clientPortal.chat_coach_bubble_text_color ? vm.clientPortal.chat_coach_bubble_text_color : '#121525',
                    chat_has_coach_image: vm.clientPortal.chat_has_coach_image ? vm.clientPortal.chat_has_coach_image : 0,
                    chat_has_coach_custom_image: vm.clientPortal.chat_has_coach_custom_image ? vm.clientPortal.chat_has_coach_custom_image : 0,
                    chat_coach_image: vm.clientPortal.chat_coach_image ? vm.clientPortal.chat_coach_image : '',
                    has_facebook: vm.clientPortal.has_facebook ? vm.clientPortal.has_facebook : 1,
                    has_twitter: vm.clientPortal.has_twitter ? vm.clientPortal.has_twitter : 1,
                    has_instagram: vm.clientPortal.has_instagram ? vm.clientPortal.has_instagram : 1,
                    has_linkedin: vm.clientPortal.has_linkedin ? vm.clientPortal.has_linkedin : 0,
                    has_youtube: vm.clientPortal.has_youtube ? vm.clientPortal.has_youtube : 0,
                    footer_facebook: vm.clientPortal.footer_facebook ? vm.clientPortal.footer_facebook : '',
                    footer_twitter: vm.clientPortal.footer_twitter ? vm.clientPortal.footer_twitter : '',
                    footer_instagram: vm.clientPortal.footer_instagram ? vm.clientPortal.footer_instagram : '',
                    footer_linkedin: vm.clientPortal.footer_linkedin ? vm.clientPortal.footer_linkedin : '',
                    footer_youtube: vm.clientPortal.footer_youtube ? vm.clientPortal.footer_youtube : '',
                    footer_company: vm.clientPortal.footer_company ? vm.clientPortal.footer_company : '',
                    footer_terms: vm.clientPortal.footer_terms ? vm.clientPortal.footer_terms : '',
                    footer_policy: vm.clientPortal.footer_policy ? vm.clientPortal.footer_policy : '',
                    delivery_sms: vm.clientPortal.delivery_sms ? vm.clientPortal.delivery_sms : '',
                    seo_fav: vm.clientPortal.seo_fav && vm.clientPortal.seo_fav != "null"  ? vm.clientPortal.seo_fav : '',
                    seo_share_title: vm.clientPortal.seo_share_title ? vm.clientPortal.seo_share_title : 'Welcome To Your Client Portal',
                    seo_share_desc: vm.clientPortal.seo_share_desc ? vm.clientPortal.seo_share_desc : 'This is your online client portal where you can access all your training.',
                    seo_thumb: vm.clientPortal.seo_thumb && vm.clientPortal.seo_thumb != "null"  ? vm.clientPortal.seo_thumb : '',
                    seo_title: vm.clientPortal.seo_title ? vm.clientPortal.seo_title : 'Welcome To Your Client Portal',
                    seo_keyword: vm.clientPortal.seo_keyword ? vm.clientPortal.seo_keyword : 'Client portal',
                    seo_desc: vm.clientPortal.seo_desc ? vm.clientPortal.seo_desc : 'This is your online client portal where you can access all your training.',
                    seo_index: vm.clientPortal.seo_index ? vm.clientPortal.seo_index : '',
                    fb_pixel: vm.clientPortal.fb_pixel ? vm.clientPortal.fb_pixel : '',
                    chat_background_color: vm.clientPortal.chat_background_color ? vm.clientPortal.chat_background_color : '#F7F7F7',
                    has_chatbox: vm.clientPortal.has_chatbox ? vm.clientPortal.has_chatbox : 0,
                    portal_chat_email_notification: vm.clientPortal.portal_chat_email_notification ? vm.clientPortal.portal_chat_email_notification : 0,
                    pinned_widget: vm.clientPortal.pinned_widget ? vm.clientPortal.pinned_widget : 0,
                    has_footer: vm.clientPortal.has_footer ? vm.clientPortal.has_footer : 0,
                    chat_placement: vm.clientPortal.chat_placement ? vm.clientPortal.chat_placement : 'right',
                    chat_button_bg_color: vm.clientPortal.chat_button_bg_color ? vm.clientPortal.chat_button_bg_color : '#2c3e50',
                    chat_button_text_color: vm.clientPortal.chat_button_text_color ? vm.clientPortal.chat_button_text_color : '#fff',
                    footer_textcolor: vm.clientPortal.footer_textcolor ? vm.clientPortal.footer_textcolor : '#2F7FED',
                    footer_bgcolor: vm.clientPortal.footer_bgcolor ? vm.clientPortal.footer_bgcolor : '#f5f5f5',
                    has_habit_score: vm.clientPortal.has_habit_score == undefined ? 1 : vm.clientPortal.has_habit_score,
                    has_progress_score: vm.clientPortal.has_progress_score == undefined ? 1 : vm.clientPortal.has_progress_score,
                    has_portal_login: vm.clientPortal.has_portal_login == undefined ? 1 : vm.clientPortal.has_portal_login,
                    has_referrals: vm.clientPortal.has_referrals == undefined ? 1 : vm.clientPortal.has_referrals,
                    display_cover: vm.clientPortal.display_cover ? vm.clientPortal.display_cover : 0,
                    headline: vm.clientPortal.headline ? vm.clientPortal.headline : '',
                    description: vm.clientPortal.description ? vm.clientPortal.description : '',
                    cover_type: vm.clientPortal.cover_type ? vm.clientPortal.cover_type : 0,
                    cover: vm.clientPortal.cover ? vm.clientPortal.cover : '',
                    has_small_screen_cover: vm.clientPortal.has_small_screen_cover ? vm.clientPortal.has_small_screen_cover : 0,
                    small_cover: vm.clientPortal.small_cover ? vm.clientPortal.small_cover : '',
                    has_cover_overlay: vm.clientPortal.has_cover_overlay ? vm.clientPortal.has_cover_overlay : 0,
                    overlay_color: vm.clientPortal.overlay_color ? vm.clientPortal.overlay_color : '',
                    overlay_opacity: vm.clientPortal.overlay_opacity ? vm.clientPortal.overlay_opacity : 20,
                    cover_background_color: vm.clientPortal.cover_background_color ? vm.clientPortal.cover_background_color : '#2F7FED',
                    display_footer: vm.clientPortal.display_footer ? vm.clientPortal.display_footer : 0,
                    footer_desktop_only: vm.clientPortal.footer_desktop_only ? vm.clientPortal.footer_desktop_only : 0,
                    activity_widget: vm.clientPortal.activity_widget ? vm.clientPortal.activity_widget : 0,
                    opac_overlay_color: vm.clientPortal.opac_overlay_color ? vm.clientPortal.opac_overlay_color : 'rgb(0, 0, 0, 0.05)',
                    delivery_sms_media: vm.clientPortal.delivery_sms_media ? JSON.parse(vm.clientPortal.delivery_sms_media) : [],
                    company_branding: vm.clientPortal.company_branding ? vm.clientPortal.company_branding : '',
                    header_bgcolor: vm.clientPortal.header_bgcolor ? vm.clientPortal.header_bgcolor : '',
                    header_textcolor: vm.clientPortal.header_textcolor ? vm.clientPortal.header_textcolor : '',
                    logo: vm.clientPortal.logo ? vm.clientPortal.logo : '',
                    author: vm.clientPortal.author ? vm.clientPortal.author : '',
                    classroom_tab_name: vm.clientPortal.classroom_tab_name ? vm.clientPortal.classroom_tab_name : 'Classroom',
                    playbook_tab_name: vm.clientPortal.playbook_tab_name ? vm.clientPortal.playbook_tab_name : 'Playbooks',
                    page_tab_name: vm.clientPortal.page_tab_name ? vm.clientPortal.page_tab_name : 'Pages',
                    form_tab_name: vm.clientPortal.form_tab_name ? vm.clientPortal.form_tab_name : 'Forms',
                    workout_tab_name: vm.clientPortal.workout_tab_name ? vm.clientPortal.workout_tab_name : 'Workouts',
                    file_tab_name: vm.clientPortal.file_tab_name ? vm.clientPortal.file_tab_name : 'Files',
                    calendar_tab_name: vm.clientPortal.calendar_tab_name ? vm.clientPortal.calendar_tab_name : 'Calendar',
                    progress_tab_name: vm.clientPortal.progress_tab_name ? vm.clientPortal.progress_tab_name : 'Tracking',
                    calendar_settings: vm.clientPortal.calendar_settings ? vm.clientPortal.calendar_settings : calendarSettings,
                    calendar_email_notification: vm.clientPortal.calendar_email_notification ? vm.clientPortal.calendar_email_notification : 0,
                    is_headline: vm.clientPortal.is_headline ? vm.clientPortal.is_headline : 0,
                    is_subheadline: vm.clientPortal.is_subheadline ? vm.clientPortal.is_subheadline : 0,
                };

                if (vm.form.login_setting.is_headline == undefined) {
                    vm.form.login_setting.is_headline = 1;
                    vm.form.login_setting.is_subheadline = 1;
                }

                vm.deliveryEmail = {
                    defaultHandler: true,
                    subject: vm.clientPortal.delivery_subject,
                    email: vm.clientPortal.delivery_email,
                    is_email_logo: vm.clientPortal.show_email_logo,
                    is_signature: vm.clientPortal.show_email_signature,
                    signature_id: vm.clientPortal.email_signature,
                    show_email_logo: vm.clientPortal.show_email_logo,
                    email_logo: vm.clientPortal.email_logo,
                    email_button_bg_color: vm.clientPortal.email_button_bg_color,
                    email_button_text_color: vm.clientPortal.email_button_text_color,
                    module_id: vm.clientPortal.id,
                };

                setTimeout(function () {
                    vm.stepContentResetWatcher += 1;
                }, 1100);
            },

            handleDefaultEmail () {
                const vm = this;
                let email = '<p><strong>Hi {{first_name}}!</strong></p>';
                    email += '<p>A new client portal has been created for you by {{company_owner}}.</p>';
                    email += '<p>In your {{company_name}} client portal, you can access the resources you need to succeed!</p>';
                    email += '<p>Inside you can learn, ask questions, and more!</p>';
                    email += '<p><br></p>';
                    email += '<p class="text-center">{{auto_login_link}}</p>';
                    email += '<p><br></p>';
                    email += "<p>If you ever get logged out don\'t worry!</p>";
                    email += '<p><br></p>';
                    email += '<p><strong>Here is the link to your client portal:</strong></p>';
                    email += '<p>{{client_portal_url}}</p>';
                    email += '<p>User ID:  {{user_name}} </p>';
                    email += '<p>Password: {{password}} </p>';
                    email += '<br>';
                    email += '<p>Thanks for being awesome {{first_name}}!</p>';
                    email += '<p>Team {{company_name}}</p>';
                    email += '<p><br></p>';
                    email += '<p><b>Agreement to copyright:</b> By accessing this information you agree to all copyrights by {{company_name}} to this intellectual property and agree not to duplicate, redistribute, or alter this information in any manner without written permission.</p>';

                vm.deliveryEmail.defaultEmail  = true;
                vm.deliveryEmail.subject  = '[ACCESS] Your New Client Portal!';
                vm.deliveryEmail.email    = email;
            },
            
            handleDefaultSms () {
                const vm = this;

                vm.form.delivery_sms = '{{company_owner}} has just created a new client portal for you.\r\nClick here to log right in! {{auto_login_sms_link}}.\r\nThe details have also been sent to your email.Cheers!\r\nTeam {{company_name}}';
            },

            handleSubmit (form, { setFieldError }) {
                const vm = this;
                const params = JSON.parse(JSON.stringify(vm.form));

                params.setFieldError            = setFieldError;
                params.delivery_subject         = vm.deliveryEmail.subject;
                params.delivery_email           = vm.deliveryEmail.email;
                params.show_email_logo          = vm.deliveryEmail.show_email_logo;
                params.show_email_signature     = vm.deliveryEmail.is_signature;
                params.email_signature          = vm.deliveryEmail.signature_id;
                params.email_logo               = vm.deliveryEmail.email_logo;
                params.email_button_bg_color    = vm.deliveryEmail.email_button_bg_color,
                params.email_button_text_color  = vm.deliveryEmail.email_button_text_color,

                vm.updatePortalSetting(params).then((resp) => {
                    if (resp) {
                        vm.edit_classroom = false;
                        vm.edit_calendar = false;
                        vm.edit_chart = false;
                        vm.edit_playbooks = false;
                        vm.edit_pages = false;
                        vm.edit_workouts = false;
                        vm.edit_forms = false;
                        vm.edit_files = false;
                    }
                });
            },

            handleCopy (string) {
                copyText(string, undefined, (error, event) => {
                    if (error) {
                        Toastr.error('Failed to copy link!');
                    } else {
                        Toastr.success('Link copied successfully!');
                    }
                })
            },

            validLink (str) {
                const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
                  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                  '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                  '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                  '(\\#[-a-z\\d_]*)?$','i'); // fragment locator

                return !!pattern.test(str);
            },

            parseEmbedCode (url) {
                const vm = this;

                return VideoParser.embedCodeFromUrl(url)
            },

            handlePreview (preview) {
                const vm = this;
                const form = vm.$refs['client-portal-setting-form'];
                const params = JSON.parse(JSON.stringify(vm.form));
                params.setFieldError = form.setFieldError;
                vm.updatePortalSetting(params);
            },

            goTo (refName) {
                const vm = this;

                let element = vm.$refs[refName];

                if (element) {
                    let top = element.offsetTop;

                    setTimeout(function() {
                        document.querySelector('.preview_content .content_area').scrollTo(0, top);
                    }, 100);
                }
            },

            setting (refName) {
                const vm = this;
                let element = vm.$refs[refName];

                // if (refName === 'ftrsetting') {
                //     vm.tab = 'footer';
                // }

                if (element) {
                    let top = element.offsetTop;
                    document.querySelector('.global_setting .tabs_content').scrollTo(0, top);
                }
            },

            updateSmsText () {
                const vm = this;

                const clientPortalSms = vm.$refs['client-portal-sms-component'];

                if (clientPortalSms) {
                    vm.clientPortalSmsText = clientPortalSms.smsMedia && clientPortalSms.smsMedia.media && clientPortalSms.smsMedia.media[0] ? vm.form.delivery_sms + '\n\n' + clientPortalSms.smsMedia.media[0].toString() : vm.form.delivery_sms;
                }
            },

            toggleSectionbar () {
                const leftSidebar = document.querySelector('.global_setting');

                if (leftSidebar.classList.contains('show_m_sidebar')) {
                    leftSidebar.classList.remove('show_m_sidebar');
                } else {
                    leftSidebar.classList.add('show_m_sidebar');
                }
            },

            resizeEventHandler () {
                const vm = this;
                let screenWidth = window.innerWidth;

                if (screenWidth < 992) {
                    vm.preview = false;
                } else {
                    vm.preview = true;
                }
            },


            handleTabRename (name , tab) {
                const vm        = this;
                const form      = vm.$refs['client-portal-setting-form'];
                const params    = {};

                params.setFieldError = form.setFieldError;

                form.validate().then((result) => {
                    if (result.valid) {
                        params[name]  = vm.form[name];
                        params.field  = name;

                        vm.renameTab(params).then((resp) => {
                            if (resp) {
                                vm[tab] = false;
                            }
                        });
                    }
                });
            },

            handleCalendarTitleUpdate (name , tab) {
                const vm        = this;
                const form      = vm.$refs['client-portal-setting-form'];
                const params    = {};
                const field     = `calendar_${name}`;

                params.setFieldError = form.setFieldError;

                form.validate().then((result) => {
                    if (result.valid) {
                        params[name]  = vm.form.calendar_settings[name];
                        params.field  = name;
                        params[field] = vm.form.calendar_settings[name];

                        vm.updateCalendarTitle(params).then((resp) => {
                            if (resp) {
                                vm[tab] = false;
                            }
                        });
                    }
                });
            },

            handleTabReset (name, value){
                const vm        = this;
                const params    = {};

                params[name]    = value;
                params.field    = name;

                vm.renameTab(params).then((resp) => {
                    if (resp) {
                        vm.form[name] = value;
                    }
                })
            },

            toggleSection (ev) {
                const vm = this;
                let parentElm = ev.currentTarget.closest('.section_content');

                if (parentElm.classList.contains('show')) {
                    parentElm.classList.remove('show');
                    vm.toggleAll = 1;
                } else {
                    parentElm.classList.add('show');
                    vm.toggleAll = 0;
                }
            },

            openAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.add('show');
                }

                vm.toggleAll = 0;
            },

            closeAllSection () {
                const vm = this;
                let allParentElm = document.querySelectorAll('.section_content');

                for (let i = 0; i < allParentElm.length; i++) {
                    allParentElm[i].classList.remove('show');
                }

                vm.toggleAll = 1;
            },
        },
    }
</script>

<style scoped>
    .global_setting .preview_content{
        width: 40%;
    }
    .client-portal-setting-form{
        display: flex;
        flex-direction: column;
        height: inherit;
    }
    :deep(.color_container .input_label) {
        color: #121525;
        font-weight: 400;
        margin: 0 0 5px 0;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 6px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .dashboard .banner {
        height: auto;
        line-height: 0;
        position: relative;
    }

    .dashboard .banner .banner_img {
        /* border-radius: 0 0 5px 5px; */
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .banner_wpr .banner_img{
        width: 100%;
        height: 100%;
        position: relative;
        left: 0;
        top: 0;
        object-fit: cover;
    }

    .dashboard .banner .bnr_content, .banner_wpr .bnr_content {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        z-index: 2;
        padding: 0 15px;
    }

    .overlay {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
    }

    .dashboard .banner .bnr_content h4 {
        /* font-size: 15px !important;
        line-height: 20px !important; */
        color: #fff;
        /* font-weight: 400 !important; */
        /* text-shadow: 0 1px 4px rgba(0, 0, 0, 0.2); */
    }

    .dashboard .banner .bnr_content h1 {
        /* font-size: 30px !important;
        line-height: 40px !important; */
        color: #fff;
        /* font-weight: 500 !important; */
        /* text-shadow: 0 4px 4px rgba(0, 0, 0, 0.6); */
    }

    .dashboard .dash_tabs {
        text-align: center;
        margin-top: -20px;
    }

    .dashboard .dash_tabs .tab_btn{
        margin: 0 10px;
    }
    .dashboard .dash_tabs .tab_btn li {
        font-size: 8px;
        line-height: 10px;
        padding: 11px 7px;
    }

    .dashboard .dash_tabs .tab_btn li:first-child {
        font-size: 12px;
        padding: 11px 7px;
        flex: 0 1 40px;
    }

    .dashboard .score_circle {
        width: 40px;
        height: 40px;
        padding: 3px;
    }
    .dashboard .score_circle .inner_circle .score{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .dashboard .score_circle .inner_circle .score span{
        font-size: 10px;
    }
    .dashboard .activity_score{
        padding: 15px 0 10px 0;
        justify-content: space-around;
    }
    .dashboard .activity_score li{
        flex: 0 0 25%;
    }
    .dashboard .activity_score li h5 {
        margin-top: 10px;
        font-size: 11px;
        line-height: 14px;
    }

    .dashboard h4 {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
    }
    .preview_content {
        position: relative;
        width: 40%;
        background: #fff;
        transition: all 0.3s ease-in-out;
        z-index: 4;
        margin-right: -40%;
    }
    .preview_content.show {
        margin-right: 0;
    }
    .preview_btn {
        padding: 10px 20px;
        border-radius: 3px;
        background: #32373b;
        color: #fff;
        font-size: 12px;
        line-height: 15px;
        font-weight: 300;
        position: fixed;
        right: -30px;
        top: 100px;
        text-transform: uppercase;
        letter-spacing: 1px;
        transform: rotate(-90deg);
        cursor: pointer;
    }
    .preview_content .close_btn{
        position: absolute;
        left: -35px;
        top: 2px;
    }
    .info_bar {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        margin: 0 auto;
        position: relative;
    }
    .info_bar .tutorial_btn{
        position: absolute;
        left: 100%;
        top: 25px;
        padding: 4px 10px;
        background: #fff;
        border-radius: 11px;
        font-size: 11px;
        line-height: 14px;
        color: #2f7eed;
        font-weight: 500;
        margin-left: 5%;
        cursor: pointer;
    }
    .time {
        min-width: 150px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 10px 25px;
        border-bottom: 1px solid #e9e9e9;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
    .time span {
        font-size: 13px;
        line-height: 16px;
        font-weight: 500;
        display: block;
    }
    .step_bar2 {
        position: relative;
        padding: 20px 15px 10px 15px;
    }
    .step_bar2 ul {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 25px;
        position: relative;
        z-index: 1;
    }

    .step_bar2 ul li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    .step_bar2 ul li h6 {
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        white-space: nowrap;
        text-align: center;
    }

    .step_bar2 ul li:first-child h6 {
        left: 0;
        transform: none;
        text-align: left;
    }

    .step_bar2 ul li:last-child h6 {
        right: 0;
        left: auto;
        transform: none;
        text-align: right;
    }

    .step_bar2 ul li span {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #fff;
        border: 3px solid #e5e5e5;
        font-size: 12px;
        font-weight: 500;
        color: #2c3e50;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
    }

    .step_bar2 .total_bar {
        position: absolute;
        content: '';
        top: 32px;
        left: 30px;
        right: 30px;
        height: 2px;
        background: #d9d9d9;
    }

    .step_bar2 .total_bar span {
        background: #a1c8ff;
        position: absolute;
        left: 0;
        height: 100%;
        transition: all 0.3s ease-in-out;
    }

    .step_bar2 ul li.active span {
        background: #2f7eed;
        color: #fff;
        border-color: #a1c8ff;
    }
    .layout_content{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 20px 0;
    }
    .global_setting .tabs_content .layout_content ul{
        justify-content: space-around;
    }
    .global_setting .tabs_content .layout_content ul li{
        padding: 10px;
    }
    .white_box{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        padding: 20px;
    }
    .inner_box{
        padding: 15px 0;
    }
    .global_setting .color_container{
        border-color: #e9e9e9;
        background: #fff;
        padding: 20px 30px;
    }
    .soon{
        font-size: 20px;
        line-height: 30px;
        font-weight: 300;
        color: #5a5a5a;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 150px;
    }
    .preview_content .cell{
        width: 405px;
        padding: 60px 15px 80px;
        border-radius: 40px;
        box-shadow: 0 0 15px 1px rgba(0,0,0,0.2);
        position: relative;
        margin: 30px auto;
    }
    .preview_content .cell:after, .preview_content .cell:before{
        position: absolute;
        content: "";
        width: 50px;
        background: #f2f2f2;
        left: 50%;
        transform: translateX(-50%);
    }
    .preview_content .cell:before{
        height: 7px;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        top: 25px;
    }
    .preview_content .cell:after{
        height: 50px;
        border: 1px solid #d9d9d9;
        border-radius: 50%;
        bottom: 15px;
    }
    .preview_content .cell .content_area{
        height: 610px;
        padding: 0;
        border: 1px solid #e7e7e7;
        background: #f5f5f5;
        position: relative;
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area{
        height: 100%;
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area .banner_wpr{
        position: relative;
        height: auto;
        background: #f5f5f5;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .preview_content .banner_area .banner_wpr picture{
        width: 100%;
    }
    .preview_content .banner_area .banner_wpr .bnr_content{
        transform: none;
        top: auto;
        left: auto;
        padding-top: 40px;
        padding-bottom: 50px;
    }
    .preview_content .banner_area2, .preview_content .banner_area3{
        height: 100%;
    }
    .preview_content .banner_area3{
        display: flex;
        flex-direction: column;
    }
    .preview_content .banner_area2 .banner_wpr{
        position: relative;
        background: #f5f5f5;
        padding: 30px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: auto;
        min-height: 100%;
    }
    .preview_content .banner_area2 .banner_wpr h4, .preview_content .banner_area2 .banner_wpr h1{
        text-align: center;
    }
    .preview_content .banner_area3 .banner_wpr{
        position: relative;
        background: #f5f5f5;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: auto;
        flex-grow: 1;
    }
    .banner_area3 .banner_wpr .banner_img{
        height: 100%;
        object-fit: cover;
        border-radius: 0;
    }
    .preview_content .banner_wpr .bnrCont_wpr{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* flex: 1; */
        /* margin-bottom: 30px; */
    }
    .preview_content .banner_area3 .banner_wpr .bnrCont_wpr{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        z-index: 2;
    }
    .preview_content .banner_wpr h1{
        font-weight: 600;
        color: rgb(255, 255, 255);
        /* text-shadow: 0 1px 4px rgba(0,0,0,0.6); */
        /* text-transform: uppercase; */
        z-index: 2;
    }
    .preview_content .banner_wpr h4{
        font-weight: 600;
        color: rgb(255, 255, 255);
        /* text-shadow: 0 1px 3px rgba(0,0,0,0.5); */
        /* text-transform: uppercase; */
        z-index: 2;
    }
    .preview_content .banner_area .login{
        position: relative;
        margin-top: -20px;
        padding: 30px;
        width: 355px;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        z-index: 3;
        margin-bottom: 40px;
        border-radius: 8px;
    }
    .preview_content .banner_area2 .login{
        padding: 30px;
        width: 100%;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        border-radius: 8px;
        position: relative;
        z-index: 1;
        background: #fff;
        max-width: 300px;
        margin: 20px auto;
    }
    .preview_content .banner_area3 .login{
        padding: 30px;
        width: 100%;
        box-shadow: 0px 4px 35px rgba(0,0,0,0.1);
        background: #fff;
        position: relative;
        z-index: 1;
    }
    .preview_content .banner_area .login h2, .preview_content .banner_area2 .login h2, .preview_content .banner_area3 .login h2{
        font-size: 17px;
        line-height: 23px;
        font-weight: 500;
        margin-bottom: 10px;
    }
    /* .preview_content .banner_area .login .form_grp, .preview_content .banner_area2 .login .form_grp, .preview_content .banner_area3 .login .form_grp{
        padding: 7px 0;
    } */
    .preview_content .banner_area .login .form_grp .input_label, .preview_content .banner_area2 .login .form_grp .input_label, .preview_content .banner_area3 .login .form_grp .input_label{
        font-size: 13px;
        line-height: 17px;
    }
    .preview_content .banner_area .login .form_grp .field_wpr input, .preview_content .banner_area2 .login .form_grp .field_wpr input, .preview_content .banner_area3 .login .form_grp .field_wpr input{
        height: 40px;
        font-size: 13px;
    }
    .preview_content .banner_area .login .group-col-2 label.checkbox span, .preview_content .banner_area2 .login .group-col-2 label.checkbox span, .preview_content .banner_area3 .login .group-col-2 label.checkbox span{
        width: 12px;
        height: 12px;
        flex: 0 0 10px;
    }
    .preview_content .banner_area .login .group-col-2 label, .preview_content .banner_area .login .group-col-2 .forgot a, .preview_content .banner_area2 .login .group-col-2 label, .preview_content .banner_area2 .login .group-col-2 .forgot a, .preview_content .banner_area3 .login .group-col-2 label, .preview_content .banner_area3 .login .group-col-2 .forgot a{
        font-size: 11px;
        line-height: 13px;
    }
    .preview_content .banner_area .login .primary_btn, .preview_content .banner_area2 .login .primary_btn, .preview_content .banner_area3 .login .primary_btn{
        font-size: 16px;
        line-height: 20px;
        height: 45px;
        letter-spacing: 2px;
        margin-bottom: 5px;
    }
    .preview_content .login .login-thumbnail .img_wpr img{
        height: auto;
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
    }
    .preview_content .dashboard_footer {
        width: 100%;
        text-align: center;
        padding: 20px 0;
    }
    .preview_content .dashboard_footer ul li{
        padding: 2px;
    }
    .preview_content .dashboard_footer ul li a {
        width: 20px;
        height: 20px;
        border: 1px solid #2f7eed;
        font-size: 10px;
        color: #2f7eed;
        text-decoration: none;
    }
    .preview_content .dashboard_footer h4 {
        font-size: 12px;
        line-height: 14px;
        margin: 10px 0 5px;
    }
    .preview_content .dashboard_footer p {
        font-size: 9px;
        line-height: 11px;
    }
    .preview_content .dashboard_footer p a:after {
        top: 5px;
        width: 2px;
        height: 2px;
    }
    .preview_content .feature_area{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;
    }
    .preview_content .feature_area .left_side{
        width: 100%;
        padding: 0 10px;
    }
    .preview_content .feature_area .right_side{
        width: 100%;
        padding: 0 10px;
    }
    .preview_content .feature_area .right_side .extra_info{
        padding: 10px 15px;
        font-size: 10px;
        line-height: 14px;
        border: 0;
    }
    .preview_content .feature_area .right_side .extra_info .action_wpr{
        position: relative;
        margin-bottom: -5px;
    }
    .preview_content .feature_area .right_side .extra_info h6 a{
        font-size: 10px;
        line-height: 12px;
    }
    .preview_content .full_width .extra_info{
        padding: 10px 15px;
        font-size: 10px;
        line-height: 14px;
        border: 0;
    }
    .preview_content .full_width .extra_info .action_wpr{
        position: relative;
        margin-bottom: -5px;
    }
    .preview_content .full_width .extra_info h6 a{
        font-size: 10px;
        line-height: 12px;
    }
    .preview_content .client_productList{
        padding: 0 10px;
    }
    .preview_content .client_productList li{
        padding: 15px 0;
    }
    /* .preview_content .client_productList .product_thumb{
        height: 80px;
    } */
    .preview_content .client_productList .product_card{
        position: relative;
        overflow: visible;
    }
    .preview_content .client_productList .product_card .product_cont{
        padding: 15px 20px 25px 20px;
        border-radius: 0 0 10px 10px;
    }
    .preview_content .client_productList .product_card .product_cont h4{
        font-size: 14px;
        line-height: 18px;
        margin-bottom: 15px;
        max-height: 35px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .preview_content .client_productList .product_card .product_cont p{
        font-size: 11px;
        line-height: 16px;
        margin-bottom: 25px;
        max-height: 45px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .preview_content .client_productList .product_card button.pin{
        right: -10px;
        top: -10px;
    }
    .preview_content .client_productList .product_card .product_cont .primary_btn{
        font-size: 13px;
        line-height: 20px;
        height: 40px;
        border-radius: 4px;
        letter-spacing: 0.4px;
    }
    .preview_content .chatbox_wpr{
        line-height: 0;
        height: 0 ;
        border-radius: 20px 20px 0 0;
        padding: 0;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        box-shadow: 0 0 15px rgba(0,0,0,0.05);
        transition: all 0.3s ease-in-out;
    }
    .preview_content .chatbox_wpr.show{
        bottom: 0;
        height: 610px;
    }
    .chatbox_wpr .chat {
        background: transparent;
        height: calc(100% - 190px);
        text-align: left;
        padding: 20px;
    }
    .preview_content .chat_btn{
        position: absolute;
        width: 100%;
        height: 24px;
        border-radius: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--var-chat-bg-color);
    }
    .preview_content .chat_btn i{
        font-size: 14px;
    }
    .preview_content .chat_btn label{
        display: block;
    }
    .preview_content .chat_close{
        position: relative;
        right: 15px;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
        z-index: 2;
    }
    .seo_card{
        max-width: 450px;
        border: 1px solid #f5f5f5;
        box-shadow: 0 1px 15px rgba(0,0,0,0.05);
        border-radius: 12px;
        overflow: hidden;
        line-height: 0;
        margin:12px 0;
    }
    .seo_card img{
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .seo_card .seo_info{
        padding: 25px 30px;
        background: #fff;
    }
    .seo_card .seo_info h3{
        font-size: 17px;
        line-height: 22px;
        color: #121525;
        font-weight: 500;
        margin-bottom: 10px;
        text-transform: capitalize;
    }
    .seo_card .seo_info p{
        font-size: 12px;
        line-height: 16px;
        color: #5a5a5a;
        font-weight: 400;
    }
    :deep(.delivery-message .group_item) {
        width: 100%;
    }

    /* :deep(.delivery-message .subject-emoji-picker) {
        margin-top: -20px;
    } */

    :deep(.swiper-wrapper .swiper-slide) {
        min-width: 120px;
    }

    .login_cover {
        margin: 0;
        padding: 0;
        background: inherit;
        border-top: 0;
        border-bottom: 1px solid #e7e7e7;
    }

    .content_area .banner_area .banner_wpr .overlay {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 2;
        border-radius: 0;
    }

    .content_area .banner_area .banner_wpr h1,
    .content_area .banner_area .banner_wpr h4 {
        font-weight: 600;
        color: #fff;
        position: relative;
        z-index: 3;
    }

    .login_cover_bg {
        background: #f5f5f5;
    }

    :deep(.login-thumbnail) {
        width: 100%;
        margin-bottom: 10px;
    }
    .extra_note{
        font-size: 30px;
        line-height: 40px;
        font-weight: 300;
        padding: 30px 70px;
        border: 1px solid #e9e9e9;
        color: #5a5a5a;
        border-radius: 10px;
        margin: 200px auto 0 auto;
    }
    .browser_layout{
        background: #f5f5f5;
        border-radius: 5px;
        padding: 5px 0 2px 0;
        max-width: 450px;
        margin: 12px 0;
    }
    .browser_tabs{
        display: flex;
        padding: 0 10px;
    }
    .browser_tabs li{
        padding: 7px 10px;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;
        background: #fff;
        flex: 0 0 150px;
        position: relative;
    }
    .browser_tabs li:before{
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 0 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        left: -8px;
    }
    .browser_tabs li:after{
        content: '';
        width: 8px;
        height: 8px;
        line-height: 16px;
        display: inline-block;
        background-image: radial-gradient(circle at 8px 0%, rgba(0, 0, 0, 0) 8px, #fff 8px);
        position: absolute;
        bottom: 0;
        right: -8px;
    }
    .browser_tabs li h6{
        font-size: 10px;
        line-height: 12px;
        font-weight: 500;
        color: #5a5a5a;
    }
    .browser_tabs li i{
        margin-left: auto;
        font-size: 10px;
        color: #5a5a5a;
    }
    .browser_tabs li img{
        max-width: 15px;
        height: auto;
        margin-right: 7px;
    }
    .browser_tabs li:last-child{
        flex: 0 0 auto;
        background: transparent;
        padding: 10px;
        position: static;
    }
    .browser_tabs li:last-child i{
        color: #121525;
    }
    .browser_info{
        display: flex;
        align-items: center;
        background: #fff;
        padding: 5px;
        margin: 0 2px;
        border-radius: 3px;
    }
    .browser_info i{
        font-size: 10px;
        margin: 0 5px;
    }
    .browser_info img.user{
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 5px;
    }
    .browser_search{
        display: flex;
        align-items: center;
        background: #f5f5f5;
        padding: 6px;
        border-radius: 12px;
        margin: 0 10px;
        flex: 1;
    }
    .browser_search p, .browser_search i{
        font-size: 9px;
        line-height: 11px;
    }
    .google_ranking{
        background: #fff;
        border-radius: 10px;
        max-width: 450px;
        padding: 30px;
        margin: 12px 0;
        box-shadow: 0 1px 15px rgba(0,0,0,0.15);
    }
    .google_ranking h6{
        font-size: 12px;
        line-height: 14px;
        color: #70757a;
        font-weight: 400;
        margin-bottom: 5px;
    }
    .google_ranking h4{
        font-size: 15px;
        line-height: 20px;
        color: #1a0dab;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .google_ranking P{
        font-size: 12px;
        line-height: 17px;
        color: #4d5156;
        font-weight: 400;
        padding: 5px 0;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .msg_preview{
        padding: 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
    }
    .sms_preview{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #eaeaea;
        margin: 15px;
        position: relative;
        border-radius: 10px;
        font-size: 12px;
        line-height: 17px;
        color: #5a5a5a;
    }
    .global_setting .msg_preview:after, .global_setting .sms_preview:after{
        position: absolute;
        content: '';
        background-image: url("data:image/svg+xml,%3Csvg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 262.83 148.44'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%23fff;stroke:%23c7c6c6;stroke-miterlimit:10;stroke-width:7px;%7D%3C/style%3E%3C/defs%3E%3Cpolyline class='cls-1' points='2.58 2.36 131.41 143.25 260.24 2.36'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        width: 20px;
        height: 13px;
        left: calc(50% - 10px);
        top: 100%;
    }
    .global_setting .sms_preview:after{
        right: 30px;
        left: auto;
    }
    .msg_preview p{
        font-size: 11px;
        line-height: 15px;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }
    .msg_preview p span{
        font-size: 14px;
        line-height: 19px;
        display: block;
        padding-bottom: 3px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msg_preview h2{
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 15px;
        font-weight: 500;
        color: #121525;
    }
    .cell .msgFooter p{
        font-size: 11px;
        line-height: 17px;
    }
    .cell .msgFooter p a{
        text-decoration: none;
    }
    .sender_info{
        display: flex;
        padding-bottom: 5px;
        margin-bottom: 40px;
        /* border-bottom: 1px solid #eaeaea; */
    }
    .sender_info img{
        width: 35px;
        height: 35px;
        margin-right: 10px;
    }
    .sender_info .info{
        margin: 0;
    }
    .sender_info .info h5{
        font-size: 13px;
        line-height: 15px;
        font-weight: 500;
        color: #2f7eed;
        margin-bottom: 3px;
    }
    .sender_info .info h6{
        font-size: 11px;
        line-height: 13px;
        font-weight: 500;
        color: #5a5a5a;
        margin-bottom: 3px;
    }
    .sender_info .info h6 span{
        color: #121525;
        font-weight: 600;
        padding-right: 5px;
    }
    .cover_type{
        margin: 10px 0 0;
        padding: 0;
        background: transparent;
    }
    .content_area.neg_margin{
        margin: 0 -50px;
        padding: 0;
    }
    .area_wpr{
        border-bottom: 1px solid #eeeeee;
        padding: 20px 50px 40px;
    }
    .area_wpr:last-of-type{
        border: 0;
    }

    .email-preview,
    :deep(.redactor-styles.redactor-in.email-preview p) {
        font-size: 11px !important;
        line-height: 15px !important;
        color: #5a5a5a;
        font-weight: 400;
        margin-bottom: 10px;
    }

    :deep(.videoWrapper) {
        position: relative;
        padding-bottom: 56.25%;
        /* 16:9 */
        /* padding-top: 25px; */
        margin-bottom: 10px;
    }

    :deep(.videoWrapper iframe) {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

    .client_card .card_header {
        padding: 15px 20px;
    }

    .client_card .card_header h4 {
        font-size: 12px;
        line-height: 15px;
    }

    .client_card .card_header .user_box>img {
        width: 20px;
        height: 20px;
    }

    .client_card .card_header .user_box h5 {
        font-size: 11px;
        line-height: 13px;
    }

    .client_card .card_header .user_info {
        margin-left: 10px;
    }

    .client_card .card_header .user_info span {
        font-size: 10px;
        line-height: 12px;
    }
    .logo_img {
        max-width: 100%;
        height: auto;
        max-height: 25px;
    }

    .banner_cover2.cover-image-sm{
        min-height: 75px;
    }

    .preview_content .banner_area3 .banner_wpr[data-v-3cc2c7bc] {
        flex-grow: unset;
    }
    .sliderWpr{
        max-width: 280px;
        margin: 0 auto;
    }
    .navbar{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
    }
    .category_navbar{
        display: flex;
        align-items: center;
        padding-right: 20px;
        gap: 10px;
    }
    .category_navbar h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        font-weight: 500;
        color: #121525;
        padding: 15px 20px;
        margin-right: auto;
    }
    .navbar .rename_fld{
        background: #f7faff;
        padding-right: 56px;
        position: relative;
        flex: 1 1 auto;
    }
    .navbar .rename_fld input{
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 12px 20px;
        background: transparent;
    }
    .navbar .category_navbar .rename_fld input{
        font-size: 16px;
        line-height: 22px;
        padding: 15px 20px;
    }
    .navbar .rename_fld button.save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .navbar .navbar_setting{
        padding: 20px 10px;
        border-top: 1px solid #e9e9e9;
    }
    .navbar .navbar_setting li{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
    }
    .navbar .navbar_setting li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
    }

    .tab-edit-container {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .tab-edit-container span.alert-danger {
        padding: 0px 20px;
        font-size: 12px;
        position: absolute;
        bottom: 0;
        color: #eb1414;
    }
    .tab-edit-container span.text-danger {
        padding: 0px 20px;
    }
    .navbar .calendar_setting{
        padding: 25px 20px;
        border-top: 1px solid #e9e9e9;
    }
    .navbar .calendar_setting li{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
    }
    .calendar_setting ul{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    .calendar_activity_setting ul{
        display: flex;
        flex-direction: column;
        gap: 25px;
    }
    .calendar_activity_setting li{
        padding: 15px 20px;
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        display: flex;
    }
    .calendar_activity_setting li .setup_controller{
        flex: 1 1 auto;
    }
    .calendar_activity_setting li .setup_controller .rename_fld{
        display: flex;
        align-items: center;
        background: #f7faff;
        padding-right: 56px;
        position: relative;
        margin-bottom: 15px;
    }
    .calendar_activity_setting li .setup_controller .rename_fld .tab-edit-container{
        flex: 1 1 auto;
    }
    .calendar_activity_setting li .setup_controller .rename_fld .tab-edit-container input{
        width: 100%;
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        color: #121525;
        padding: 12px 20px;
        background: transparent;
    }
    .calendar_activity_setting li .setup_controller .rename_fld button.save_btn{
        padding: 3px 8px;
        background: #2f7eed;
        color: #fff;
        border-radius: 3px;
        font-size: 8px;
        line-height: 10px;
        text-transform: uppercase;
        cursor: pointer;
        text-wrap: nowrap;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
    }
    .calendar_activity_setting li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        padding: 10px 0;
    }
    .calendar_activity_setting li .sample_preview{
        width: 300px;
        flex-shrink: 0;
        background: #fbfbfb;
        margin: -15px -20px -15px 20px;
        padding: 15px 30px;
        border-left: 1px solid #e9e9e9;
        border-radius: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .sample_preview .drawer_area{
        background: #fff;
        border: 1px solid #e9e9e9;
        border-radius: 5px;
        overflow: hidden;
    }
    .sample_preview .drawer_area .header{
        padding: 12px 15px;
        border-bottom: 1px solid #eaeaea;
        font-size: 13px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }
    .sample_preview .drawer_area .footer{
        padding: 12px 15px;
        border-top: 1px solid #eaeaea;
        margin-top: 30px;
        display: flex;
        justify-content: flex-end;
    }
    .sample_preview .drawer_area .footer span{
        border: 1px solid #DBDDE1;
        border-radius: 2px;
        padding: 2px 10px;
        font-size: 11px;
        line-height: 16px;
        color: #121525;
        font-weight: 500;
    }
    .sample_preview .drawer_area .body_area .slot_label{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        background: #fff;
        border-bottom: 1px solid #fff;
        position: relative;
    }
    .sample_preview .drawer_area .body_area .slot_label:before{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        z-index: -1;
    }
    .sample_preview .drawer_area .body_area .slot_label:after{
        position: absolute;
        content: '';
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #f5f5f5;
        z-index: 1;
    }
    .sample_preview .drawer_area .body_area .slot_label h5{
        font-size: 11px;
        line-height: 15px;
        font-weight: 500;
        color: #121525;
        z-index: 2;
    }
    .sample_preview .drawer_area .body_area .slot_label svg{
        z-index: 2;
    }
    .sample_preview .drawer_area .body_area .slot_label.morning svg{
        height: 10px;
        width: 19px;
    }
    .sample_preview .drawer_area .body_area .slot_label svg.morning path{
        fill: var(--var-calendar-morning-icon-color, #ff7553);
    }
    .sample_preview .drawer_area .body_area .slot_label.morning:after {
        background-color: var(--var-calendar-morning-icon-color-light, #ff755325);
    }
    .sample_preview .drawer_area .body_area .slot_label.morning h5 {
        color: var(--var-calendar-morning-text-color, #121525);
    }

    .sample_preview .drawer_area .body_area .slot_label.afternoon svg{
        height: 15px;
        width: 19px;
    }
    .sample_preview .drawer_area .body_area .slot_label svg.afternoon path{
        fill: var(--var-calendar-afternoon-icon-color, #f2a31d);
    }
    .sample_preview .drawer_area .body_area .slot_label.afternoon:after {
        background-color: var(--var-calendar-afternoon-icon-color-light, #f2a31d25);
    }
    .sample_preview .drawer_area .body_area .slot_label.afternoon h5 {
        color: var(--var-calendar-afternoon-text-color, #121525);
    }

    .sample_preview .drawer_area .body_area .slot_label.evening svg{
        height: 15px;
        width: 19px;
    }
    .sample_preview .drawer_area .body_area .slot_label svg.evening path{
        fill: var(--var-calendar-evening-icon-color, #548fe3);
    }
    .sample_preview .drawer_area .body_area .slot_label.evening:after {
        background-color: var(--var-calendar-evening-icon-color-light, #548fe325);
    }
    .sample_preview .drawer_area .body_area .slot_label.evening h5 {
        color: var(--var-calendar-evening-text-color, #121525);
    }

    .sample_preview .drawer_area .body_area .task_title{
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        color: #121525;
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }
    .sample_preview .drawer_area .body_area .task_title span.tag {
        margin-left: auto;
        padding: 1px 6px;
        border-radius: 8px;
        font-size: 9px;
        line-height: 15px;
        font-weight: 500;
        background-color: var(--var-calendar-event-tag-color,#ecf4ff);
        color: var(--var-calendar-event-tag-text-color,#2f7eed);
    }
    .sample_preview .drawer_area .body_area .task_content{
        margin-top: 5px;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    .sample_preview .drawer_area .body_area .task_content a{
        font-size: 9px;
        line-height: 12px;
        color: var(--var-calendar-event-cta-color,#2f7eed);
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        margin: 0 0 0 auto;
        text-decoration: none;
    }
    .navbar .calendar_setting li h4{
        font-size: 15px;
        line-height: 22px;
        font-weight: 500;
        padding: 10px 0;
    }
    .reset_tab{
        margin-left: auto;
    }
    .fa-undo{
        cursor: pointer;
    }

    /* toggle_section */
    .btn_list{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0 20px;
    }
    .btn_list li{
        background: #fff;
        border-radius: 10px;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        color: #121525;
        padding: 3px 10px;
        cursor: pointer;
    }
    .section_content .section_header .status {
        font-size: 13px;
        line-height: 18px;
        font-weight: 500;
        margin: 0 0 0 auto;
        flex-grow: 0;
    }
    .toggle_section{
        width: 25px;
        height: 25px;
        font-size: 16px;
        color: #999;
        cursor: pointer;
        margin-left: 15px;
    }
    .toggle_section i{
        transform: rotate(0);
        transition: all 0.3s ease-in-out;
    }

    .global_setting .tabs_content .section_content{
        position: relative;
    }
    .global_setting .tabs_content .section_content .section_header{
        margin: 10px 0;
    }
    .global_setting .tabs_content .section_content:after{
        content: '';
        position: absolute;
        left: 20px;
        top: 100%;
        right:20px;
        border-bottom: 1px solid #e9e9e9;
    }
    .global_setting .tabs_content .section_content.show:after, .global_setting .tabs_content .section_content:last-of-type:after, .global_setting .tabs_content .section_content.open_area:after{
        border: 0;
    }
    .global_setting .tabs_content .section_content .toggle_content{
        max-height: 0;
        overflow: hidden;
        animation: smoothSlidedown 0.3s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_content{
        max-height: 1000vh;
        animation: smoothSlideup 0.8s;
        animation-timing-function: cubic-bezier(1.0, 0.5);
    }
    .global_setting .tabs_content .section_content.show .toggle_section i{
        transform: rotate(-180deg);
    }

    /* --- */
    .chatbox_wpr{
        background-color: #fff !important;
        border-radius: 10px 0 0 10px;
        height: 400px;
        width: 100%;
        position: relative;
        margin: 0 0 0 auto;
        right: 0;
        box-shadow: 0 0 35px rgba(0,0,0,0.15);
        transition: all 0.3s ease-in-out;
    }
    .chat_header{
        padding: 20px 25px 25px 25px;
        background: var(--var-welcome-bg-color);
        border-radius: 0;
        position: sticky;
        top: 0;
        left: 0;
        text-align: left;
    }
    .chat_header:before {
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 15px;
        background: url('~@/assets/images/chat_curve.svg');
        background-repeat: no-repeat;
        background-size: cover;
    }
    .chatbox_wpr .chat_header h2 {
        font-size: 18px;
        line-height: 25px;
        font-weight: 400;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-bottom: 7px;
    }
    .chatbox_wpr .chat_header h4 {
        font-size: 12px;
        line-height: 17px;
        font-weight: 300;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-bottom: 15px;
    }
    .chatbox_wpr .chat_header .coach h5 {
        font-size: 15px;
        line-height: 20px;
        font-weight: 500;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
    }
    .chatbox_wpr .chat_header .coach h5 span {
        display: block;
        font-size: 11px;
        line-height: 14px;
        font-weight: 400;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-top: 3px;
    }
    .chatbox_wpr .chat_header .coach .coach_box{
        width: 40px;
        height: 40px;
        margin-right: 15px;
        padding: 3px;
    }
    .chatbox_wpr .chat_header .coach .coach_box .coach_name{
        font-size: 15px;
        line-height: 20px;
        color: var(--var-welcome-bg-color);
    }
    .chat .chat_user .coach_box .coach_name{
        color: var(--var-welcome-bg-color);
    }
    .settings_preview{
        display: flex;
        padding: 20px;
    }
    .settings_preview .setting_wpr{
        flex: 1 1 auto;
    }
    .settings_preview .sample_preview{
        width: 300px;
        flex-shrink: 0;
        background: #fbfbfb;
        margin: -20px -30px -20px 30px;
        padding: 15px 20px;
        border-left: 1px solid #e9e9e9;
        border-radius: 0 5px 5px 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .sample_preview .chat_header{
        padding: 15px;
    }
    .sample_preview .chat_header:before {
        height: 10px;
    }
    .sample_preview .chat_header h2{
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-bottom: 5px;
    }
    .sample_preview .chat_header h4{
        font-size: 10px;
        line-height: 13px;
        font-weight: 300;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-bottom: 12px;
    }
    .sample_preview .chat_header .coach h5{
        font-size: 12px;
        line-height: 15px;
        font-weight: 500;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
    }
    .sample_preview .chat_header .coach h5 span{
        display: block;
        font-size: 9px;
        line-height: 11px;
        font-weight: 400;
        /* color: var(--var-welcome-text-color); */
        color: #fff;
        padding-top: 3px;
    }
    .sample_preview .chat_header .coach .coach_box{
        width: 40px;
        height: 40px;
        margin-right: 12px;
        padding: 3px;
    }
    .sample_preview .chat_header .coach .coach_box .coach_name{
        font-size: 14px;
        line-height: 18px;
        color: var(--var-welcome-bg-color);
    }
    .sample_preview .interaction{
        background: #fff;
        height: 160px;
        padding: 5px 15px;
    }
    .sample_preview .interaction ul li{
        margin: 15px 0;
    }
    .sample_preview .interaction ul li:nth-child(odd) {
        background: var(--var-coach-bubble-bg-color);
        padding: 15px;
        border-radius: 12px 12px 12px 2px;
        margin-right: auto;
    }
    .sample_preview .interaction ul li:nth-child(even) {
        background: var(--var-client-bubble-bg-color);
        padding: 15px;
        border-radius: 12px 12px 2px 12px;
        margin-left: auto;
    }
    .sample_preview .chat{
        background: #fff;
        height: 240px;
        padding: 5px 15px;
    }
    .sample_preview .chat ul li{
        margin: 15px 0;
        max-width: 80%;
        font-size: 11px;
        line-height: 15px;
        font-weight: 400;
    }
    .sample_preview .chat ul li.coach {
        background: var(--var-coach-bubble-bg-color);
        color: var(--var-coach-bubble-text-color);
        padding: 10px;
        border-radius: 15px 15px 15px 2px;
        margin-right: auto;
    }
    .sample_preview .chat ul li.client {
        background: var(--var-client-bubble-bg-color);
        color: var(--var-client-bubble-text-color);
        padding: 10px;
        border-radius: 15px 15px 2px 15px;
        margin-left: auto;
    }
    .sample_preview .chat_btn{
        width: 80px;
        height: 80px;
        position: relative;
        right: auto;
        left: auto;
        margin: 20px auto;
    }
    .sample_preview .chat_btn:before{
        position: absolute;
        content: '';
        background: var(--var-chat-bg-color90);
        width: 100%;
        height: 100%;
        border-radius: 50%;
        left: 0;
        top: 0;
        z-index: 1;
        display: block;
    }
    .sample_preview .chat_btn label{
        display: block;
        font-size: 30px;
        line-height: 35px;
        font-weight: 500;
    }
    .sample_preview .chat_btn img{
        width: 80px;
        height: 80px;
    }
    .sample_preview .chat_btn.ripple{
        animation: ripple 0.7s linear infinite;
    }
    .chat .chat_user .msg p{
        background: var(--var-coach-bubble-bg-color);
        color: var(--var-coach-bubble-text-color);
    }
    .chat .chat_client .msg p{
        background: var(--var-client-bubble-bg-color);
        color: var(--var-client-bubble-text-color);
    }
    .cell .widget_cards{
        padding: 40px 0 5px 0;
    }
    .cell .widget_cards li{
        margin-bottom: 30px;
    }
    .cell :deep(.welcome_box .box_footer),
    .cell :deep(.task_box .box_footer),
    .cell :deep(.announcement_box .box_footer),
    .cell :deep(.announcement_box .announcement_text .cta_btn a),
    .cell :deep(.announcement_box .announcement_text .pin),
    .cell :deep(.task_item .task_details .go_btn){
        pointer-events: none;
    }

    .cell :deep(.welcome_box h3),
    .cell :deep(.announcement_box .box_header h3),
    .cell :deep(.task_box .box_header h3){
        font-size: 18px;
        line-height: 25px;
    }
    .cell :deep(.welcome_box),
    .cell :deep(.announcement_box .box_content),
    .cell :deep(.task_box .box_content){
        max-height: none;
    }
    .cell :deep(.welcome_box .videoWrapper) {
        position: static;
        padding-bottom: 0;
        margin-bottom: 0;
    }
    .cell :deep(.welcome_box .welcome_info p) {
        font-size: 15px;
        line-height: 27px;
    }
    .cell .client_card nav .mobile_bar{
        display: block;
        margin-right: 0;
    }
    .cell .client_card nav .mobile_bar svg {
        width: 16px;
        height: 16px;
    }
    .client_card nav .mobile_bar svg *{
        fill: var(--var-header-text-color);
    } 
    @keyframes ripple {
        0% {
            box-shadow: 0 0 0 0 var(--var-chat-bg-color90), 0 0 0 10px var(--var-chat-bg-color90);
        }
        100% {
            box-shadow: 0 0 0 10px var(--var-chat-bg-color90), 0 0 0 20px var(--var-chat-bg-color0);
        }
    }
    @media(max-width: 1199px){
        .info_bar{
            margin: 20px 10px;
        }
        .step_bar2{
            padding: 20px 20px 10px 20px;
        }
        .btn_list {
            padding: 0 10px;
        }
        .navbar .calendar_setting li .sample_preview{
            display: none;
        }
    }
    @media(max-width: 767px){
        .step_bar2 ul li h6 label{
            display: none;
        }
    }
    @media(max-width: 499px){
        .btn_list {
            padding: 0;
        }
    }
</style>
